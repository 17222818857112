import { replace, find } from 'lodash';
import { actionTypes } from '../actions';
import { GATEWAY_HOST, GATEWAY_API } from '../../utils/properties';
import { isEmpty } from '../../utils/common';

const GATEWAY = GATEWAY_HOST;
const REPLACE_IP = 'http://localhost:1938';

export const primaryInitialState = {
    orders: [],
    ordersLoading: false,
    pagination: { current: 1, pageSize: 100 },
    sorter: {
        order: 'descend',
        field: 'latest',
        columnKey: 'latest'
    },
    status: { operator: 'ne', value: 'CLOSED' },
    search: '',
    ordersCount: null,
    doctors: [],
    doctorsLoading: false,
    versionIncrement: false,
    formIsLoading: true,
    newOrder: {
        structureDefault: ['template', 'tray', 'equipment'],
        isOpen: false,
        isCreateOpen: false,
        isUploading: '',
        bill: '',
        organisation: null,
        bill_date: null,
        deadline: null,
        isPublic: false,
        image_1: [],
        image_2: [],
        image_3: [],
        image_4: [],
        image_5: [],
        image_6: [],
        image_7: [],
        image_8: [],
        image_9: [],
        image_10: [],
        label_1: '#1',
        label_2: '#2',
        label_3: '#3',
        label_4: '#4',
        label_5: '#5',
        stl_1: [],
        stl_2: [],
        stl_3: [],
        stl_4: [],
        stl_5: [],
        stl_6: [],
        stl_7: [],
        stl_8: [],
        stl_9: [],
        stl_10: [],
        stl_11: [],
        stl_12: [],
        stl_13: [],
        stl_14: [],
        stl_15: [],
        stl_16: [],
        stl_17: [],
        stl_18: [],
        stl_19: [],
        stl_20: [],
        video: [],
        gif: [],
        gif2d: [],
        gif_splint: [],
        zip: [],
        modelMode: 'simple',
        patient: '',
        added: 1,
        minivintsAdded: 1,
        templateAdded: 1,
        toolAdded: 1,
        tool2dAdded: 1,
        vsAdded: 1,
        splintAdded: 1,
        search: '',
        doctor: null,
        clinic: null,
        status: 'NEW',
        status_minivints: 'NONE',
        status_template: 'NONE',
        status_equipment: 'NONE',
        status_equipment_2d: 'NONE',
        status_braces: 'NONE',
        status_aligners: 'NONE',
        status_tray: 'NONE',
        status_splint: 'NONE',
        version: null
    }
};

function prepareImage(imageSrc, type) {
    let image = [];
    if (!isEmpty(imageSrc)) {
        image = [imageSrc];
        image[0].uid = image[0].id;
        image[0].key = image[0].id;
        image[0].status = 'done';
        image[0].response = [imageSrc];
        if (type === 'STL') {
            image[0].type = '';
            image[0].thumbUrl = '';
            image[0].rawUrl = `${GATEWAY}${replace(
                image[0].url,
                REPLACE_IP,
                ''
            )}`;
            delete image[0].url;
        } else {
            image[0].url = `${GATEWAY}${replace(image[0].url, REPLACE_IP, '')}`;
        }
    }
    return image;
}

const OrderReducer = (state = primaryInitialState, action) => {
    switch (action.type) {
        case actionTypes.ORDERS_CHANGE_SEARCH: {
            return {
                ...state,
                ...{
                    search: action.search
                }
            };
        }
        case actionTypes.SEARCH_DOCTORS_SUCCESS: {
            return {
                ...state,
                ...{
                    doctors: action.doctors,
                    doctorsLoading: false
                }
            };
        }
        case actionTypes.SEARCH_TECHNICIANS_SUCCESS: {
            return {
                ...state,
                ...{
                    technicians: action.technicians,
                    techniciansLoading: false
                }
            };
        }
        case actionTypes.SEARCH_DOCTORS: {
            return {
                ...state,
                ...{
                    doctorsLoading: true
                }
            };
        }
        case actionTypes.SEARCH_TECHNICIANS: {
            return {
                ...state,
                ...{
                    techniciansLoading: true
                }
            };
        }
        case actionTypes.ORDERS_CHANGE_FILTER: {
            const mode = action.filter;
            let status = {
                operator: mode === 'ACTIVE' ? 'ne' : 'eq',
                value: 'CLOSED'
            };
            if (mode === 'ALL') {
                status = null;
            }
            return {
                ...state,
                ...{
                    status
                }
            };
        }
        case actionTypes.ORDERS_CHANGE_SORTER: {
            let order = 'descend';
            if (action.sorter === 'patient') {
                order = 'ascend';
            }
            const newSorter = {
                order,
                field: action.sorter,
                columnKey: action.sorter
            };
            return {
                ...state,
                ...{
                    sorter: newSorter
                }
            };
        }
        case actionTypes.LOAD_ORDERS:
            return {
                ...state,
                ...{
                    ordersLoading: true,
                    orders: [],
                    pagination: action.pagination,
                    sorter: action.sorter,
                    filters: action.filters
                }
            };
        case actionTypes.LOAD_ORDERS_SUCCESS:
            return {
                ...state,
                ...{
                    orders: action.data,
                    ordersLoading: false
                }
            };
        case actionTypes.LOAD_ORDERS_COUNT_SUCCESS:
            return {
                ...state,
                ...{
                    ordersCount: action.data
                }
            };
        case actionTypes.NEW_COMMENT_SUCCESS: {
            const { selectedOrder } = state;
            let result = state;
            if (!isEmpty(selectedOrder)) {
                selectedOrder.comments = action.data;
            }
            result = {
                ...state,
                selectedOrder: {
                    ...selectedOrder
                }
            };
            return result;
        }
        case actionTypes.LOAD_ORDERS_MESSAGES_SUCCESS: {
            let { selectedOrder } = state;
            let result = state;
            if (!isEmpty(selectedOrder)) {
                let { messages } = selectedOrder;
                selectedOrder = find(
                    action.data,
                    (o) => o.id === selectedOrder.id
                );
                if (!isEmpty(selectedOrder)) {
                    messages = selectedOrder.messages;
                }
                result = {
                    ...state,
                    selectedOrder: {
                        ...state.selectedOrder,
                        messages
                    }
                };
            }
            return result;
        }
        case actionTypes.LOAD_ORDER_SUCCESS: {
            let image1 = [];
            let image2 = [];
            let image3 = [];
            let image4 = [];
            let image5 = [];
            let minivintsImage1 = [];
            let minivintsImage2 = [];
            let minivintsImage3 = [];
            let minivintsImage4 = [];
            let minivintsImage5 = [];
            let minivintsImage6 = [];
            let minivintsImage7 = [];
            let minivintsImage8 = [];
            let minivintsImage9 = [];
            let minivintsImage10 = [];
            let templateImage1 = [];
            let templateImage2 = [];
            let templateImage3 = [];
            let templateImage4 = [];
            let templateImage5 = [];
            let templateImage6 = [];
            let templateImage7 = [];
            let templateImage8 = [];
            let templateImage9 = [];
            let templateImage10 = [];
            let toolImage1 = [];
            let toolImage2 = [];
            let toolImage3 = [];
            let toolImage4 = [];
            let toolImage5 = [];
            let toolImage6 = [];
            let toolImage7 = [];
            let toolImage8 = [];
            let toolImage9 = [];
            let toolImage10 = [];
            let tool2dImage1 = [];
            let tool2dImage2 = [];
            let tool2dImage3 = [];
            let tool2dImage4 = [];
            let tool2dImage5 = [];
            let tool2dImage6 = [];
            let tool2dImage7 = [];
            let tool2dImage8 = [];
            let tool2dImage9 = [];
            let tool2dImage10 = [];
            let vsImage1 = [];
            let vsImage2 = [];
            let vsImage3 = [];
            let vsImage4 = [];
            let vsImage5 = [];
            let vsImage6 = [];
            let vsImage7 = [];
            let vsImage8 = [];
            let vsImage9 = [];
            let vsImage10 = [];
            let vsImage11 = [];
            let vsImage12 = [];
            let vsImage13 = [];
            let vsImage14 = [];
            let vsImage15 = [];
            let splintImage1 = [];
            let splintImage2 = [];
            let splintImage3 = [];
            let splintImage4 = [];
            let splintImage5 = [];
            let splintImage6 = [];
            let splintImage7 = [];
            let splintImage8 = [];
            let splintImage9 = [];
            let splintImage10 = [];
            let stl1 = [];
            let stl2 = [];
            let stl3 = [];
            let stl4 = [];
            let video = [];
            let gif = [];
            let gif2d = [];
            let gif_splint = [];
            let zip = [];
            let stl5 = [];
            let stl6 = [];
            let stl7 = [];
            let stl8 = [];
            let stl9 = [];
            let stl10 = [];
            let stl11 = [];
            let stl12 = [];
            let stl13 = [];
            let stl14 = [];
            let stl15 = [];
            let stl16 = [];
            let stl17 = [];
            let stl18 = [];
            let stl19 = [];
            let stl20 = [];
            let added = 1;
            let minivintsAdded = 1;
            let templateAdded = 1;
            let toolAdded = 1;
            let tool2dAdded = 1;
            let vsAdded = 1;
            let splintAdded = 1;
            if (!isEmpty(action.order.image_1)) {
                image1 = prepareImage(action.order.image_1);
            }
            if (!isEmpty(action.order.image_2)) {
                added += 1;
                image2 = prepareImage(action.order.image_2);
            }
            if (!isEmpty(action.order.image_3)) {
                added += 1;
                image3 = prepareImage(action.order.image_3);
            }
            if (!isEmpty(action.order.image_4)) {
                added += 1;
                image4 = prepareImage(action.order.image_4);
            }
            if (!isEmpty(action.order.image_5)) {
                added += 1;
                image5 = prepareImage(action.order.image_5);
            }

            // Minivints
            if (!isEmpty(action.order.minivints_image_1)) {
                minivintsAdded += 1;
                minivintsImage1 = prepareImage(action.order.minivints_image_1);
            }

            if (!isEmpty(action.order.minivints_image_2)) {
                minivintsAdded += 1;
                minivintsImage2 = prepareImage(action.order.minivints_image_2);
            }

            if (!isEmpty(action.order.minivints_image_3)) {
                minivintsAdded += 1;
                minivintsImage3 = prepareImage(action.order.minivints_image_3);
            }

            if (!isEmpty(action.order.minivints_image_4)) {
                minivintsAdded += 1;
                minivintsImage4 = prepareImage(action.order.minivints_image_4);
            }

            if (!isEmpty(action.order.minivints_image_5)) {
                minivintsAdded += 1;
                minivintsImage5 = prepareImage(action.order.minivints_image_5);
            }

            if (!isEmpty(action.order.minivints_image_6)) {
                minivintsAdded += 1;
                minivintsImage6 = prepareImage(action.order.minivints_image_6);
            }

            if (!isEmpty(action.order.minivints_image_7)) {
                minivintsAdded += 1;
                minivintsImage7 = prepareImage(action.order.minivints_image_7);
            }

            if (!isEmpty(action.order.minivints_image_8)) {
                minivintsAdded += 1;
                minivintsImage8 = prepareImage(action.order.minivints_image_8);
            }

            if (!isEmpty(action.order.minivints_image_9)) {
                minivintsAdded += 1;
                minivintsImage9 = prepareImage(action.order.minivints_image_9);
            }

            if (!isEmpty(action.order.minivints_image_10)) {
                minivintsAdded += 1;
                minivintsImage10 = prepareImage(
                    action.order.minivints_image_10
                );
            }

            // Template
            if (!isEmpty(action.order.template_image_1)) {
                templateAdded += 1;
                templateImage1 = prepareImage(action.order.template_image_1);
            }

            if (!isEmpty(action.order.template_image_2)) {
                templateAdded += 1;
                templateImage2 = prepareImage(action.order.template_image_2);
            }

            if (!isEmpty(action.order.template_image_3)) {
                templateAdded += 1;
                templateImage3 = prepareImage(action.order.template_image_3);
            }

            if (!isEmpty(action.order.template_image_4)) {
                templateAdded += 1;
                templateImage4 = prepareImage(action.order.template_image_4);
            }

            if (!isEmpty(action.order.template_image_5)) {
                templateAdded += 1;
                templateImage5 = prepareImage(action.order.template_image_5);
            }

            if (!isEmpty(action.order.template_image_6)) {
                templateAdded += 1;
                templateImage6 = prepareImage(action.order.template_image_6);
            }

            if (!isEmpty(action.order.template_image_7)) {
                templateAdded += 1;
                templateImage7 = prepareImage(action.order.template_image_7);
            }

            if (!isEmpty(action.order.template_image_8)) {
                templateAdded += 1;
                templateImage8 = prepareImage(action.order.template_image_8);
            }

            if (!isEmpty(action.order.template_image_9)) {
                templateAdded += 1;
                templateImage9 = prepareImage(action.order.template_image_9);
            }

            if (!isEmpty(action.order.template_image_10)) {
                templateAdded += 1;
                templateImage10 = prepareImage(action.order.template_image_10);
            }

            // Tool
            if (!isEmpty(action.order.tool_image_1)) {
                toolAdded += 1;
                toolImage1 = prepareImage(action.order.tool_image_1);
            }

            if (!isEmpty(action.order.tool_image_2)) {
                toolAdded += 1;
                toolImage2 = prepareImage(action.order.tool_image_2);
            }

            if (!isEmpty(action.order.tool_image_3)) {
                toolAdded += 1;
                toolImage3 = prepareImage(action.order.tool_image_3);
            }

            if (!isEmpty(action.order.tool_image_4)) {
                toolAdded += 1;
                toolImage4 = prepareImage(action.order.tool_image_4);
            }

            if (!isEmpty(action.order.tool_image_5)) {
                toolAdded += 1;
                toolImage5 = prepareImage(action.order.tool_image_5);
            }

            if (!isEmpty(action.order.tool_image_6)) {
                toolAdded += 1;
                toolImage6 = prepareImage(action.order.tool_image_6);
            }

            if (!isEmpty(action.order.tool_image_7)) {
                toolAdded += 1;
                toolImage7 = prepareImage(action.order.tool_image_7);
            }

            if (!isEmpty(action.order.tool_image_8)) {
                toolAdded += 1;
                toolImage8 = prepareImage(action.order.tool_image_8);
            }

            if (!isEmpty(action.order.tool_image_9)) {
                toolAdded += 1;
                toolImage9 = prepareImage(action.order.tool_image_9);
            }

            if (!isEmpty(action.order.tool_image_10)) {
                toolAdded += 1;
                toolImage10 = prepareImage(action.order.tool_image_10);
            }

            // Tool 2d
            if (!isEmpty(action.order.tool_2d_image_1)) {
                tool2dAdded += 1;
                tool2dImage1 = prepareImage(action.order.tool_2d_image_1);
            }

            if (!isEmpty(action.order.tool_2d_image_2)) {
                tool2dAdded += 1;
                tool2dImage2 = prepareImage(action.order.tool_2d_image_2);
            }

            if (!isEmpty(action.order.tool_2d_image_3)) {
                tool2dAdded += 1;
                tool2dImage3 = prepareImage(action.order.tool_2d_image_3);
            }

            if (!isEmpty(action.order.tool_2d_image_4)) {
                tool2dAdded += 1;
                tool2dImage4 = prepareImage(action.order.tool_2d_image_4);
            }

            if (!isEmpty(action.order.tool_2d_image_5)) {
                tool2dAdded += 1;
                tool2dImage5 = prepareImage(action.order.tool_2d_image_5);
            }

            if (!isEmpty(action.order.tool_2d_image_6)) {
                tool2dAdded += 1;
                tool2dImage6 = prepareImage(action.order.tool_2d_image_6);
            }

            if (!isEmpty(action.order.tool_2d_image_7)) {
                tool2dAdded += 1;
                tool2dImage7 = prepareImage(action.order.tool_2d_image_7);
            }

            if (!isEmpty(action.order.tool_2d_image_8)) {
                tool2dAdded += 1;
                tool2dImage8 = prepareImage(action.order.tool_2d_image_8);
            }

            if (!isEmpty(action.order.tool_2d_image_9)) {
                tool2dAdded += 1;
                tool2dImage9 = prepareImage(action.order.tool_2d_image_9);
            }

            if (!isEmpty(action.order.tool_2d_image_10)) {
                tool2dAdded += 1;
                tool2dImage10 = prepareImage(action.order.tool_2d_image_10);
            }

            // Virtual Setup
            if (!isEmpty(action.order.vs_image_1)) {
                vsAdded += 1;
                vsImage1 = prepareImage(action.order.vs_image_1);
            }

            if (!isEmpty(action.order.vs_image_2)) {
                vsAdded += 1;
                vsImage2 = prepareImage(action.order.vs_image_2);
            }

            if (!isEmpty(action.order.vs_image_3)) {
                vsAdded += 1;
                vsImage3 = prepareImage(action.order.vs_image_3);
            }

            if (!isEmpty(action.order.vs_image_4)) {
                vsAdded += 1;
                vsImage4 = prepareImage(action.order.vs_image_4);
            }

            if (!isEmpty(action.order.vs_image_5)) {
                vsAdded += 1;
                vsImage5 = prepareImage(action.order.vs_image_5);
            }

            if (!isEmpty(action.order.vs_image_6)) {
                vsAdded += 1;
                vsImage6 = prepareImage(action.order.vs_image_6);
            }

            if (!isEmpty(action.order.vs_image_7)) {
                vsAdded += 1;
                vsImage7 = prepareImage(action.order.vs_image_7);
            }

            if (!isEmpty(action.order.vs_image_8)) {
                vsAdded += 1;
                vsImage8 = prepareImage(action.order.vs_image_8);
            }

            if (!isEmpty(action.order.vs_image_9)) {
                vsAdded += 1;
                vsImage9 = prepareImage(action.order.vs_image_9);
            }

            if (!isEmpty(action.order.vs_image_10)) {
                vsAdded += 1;
                vsImage10 = prepareImage(action.order.vs_image_10);
            }
            if (!isEmpty(action.order.vs_image_11)) {
                vsAdded += 1;
                vsImage11 = prepareImage(action.order.vs_image_11);
            }
            if (!isEmpty(action.order.vs_image_12)) {
                vsAdded += 1;
                vsImage12 = prepareImage(action.order.vs_image_12);
            }
            if (!isEmpty(action.order.vs_image_13)) {
                vsAdded += 1;
                vsImage13 = prepareImage(action.order.vs_image_13);
            }
            if (!isEmpty(action.order.vs_image_14)) {
                vsAdded += 1;
                vsImage14 = prepareImage(action.order.vs_image_14);
            }
            if (!isEmpty(action.order.vs_image_15)) {
                vsAdded += 1;
                vsImage15 = prepareImage(action.order.vs_image_15);
            }

            // Splint
            if (!isEmpty(action.order.splint_image_1)) {
                splintAdded += 1;
                splintImage1 = prepareImage(action.order.splint_image_1);
            }

            if (!isEmpty(action.order.splint_image_2)) {
                splintAdded += 1;
                splintImage2 = prepareImage(action.order.splint_image_2);
            }

            if (!isEmpty(action.order.splint_image_3)) {
                splintAdded += 1;
                splintImage3 = prepareImage(action.order.splint_image_3);
            }

            if (!isEmpty(action.order.splint_image_4)) {
                splintAdded += 1;
                splintImage4 = prepareImage(action.order.splint_image_4);
            }

            if (!isEmpty(action.order.splint_image_5)) {
                splintAdded += 1;
                splintImage5 = prepareImage(action.order.splint_image_5);
            }

            if (!isEmpty(action.order.splint_image_6)) {
                splintAdded += 1;
                splintImage6 = prepareImage(action.order.splint_image_6);
            }

            if (!isEmpty(action.order.splint_image_7)) {
                splintAdded += 1;
                splintImage7 = prepareImage(action.order.splint_image_7);
            }

            if (!isEmpty(action.order.splint_image_8)) {
                splintAdded += 1;
                splintImage8 = prepareImage(action.order.splint_image_8);
            }

            if (!isEmpty(action.order.splint_image_9)) {
                splintAdded += 1;
                splintImage9 = prepareImage(action.order.splint_image_9);
            }

            if (!isEmpty(action.order.splint_image_10)) {
                splintAdded += 1;
                splintImage10 = prepareImage(action.order.splint_image_10);
            }

            if (!isEmpty(action.order.stl_1)) {
                stl1 = prepareImage(action.order.stl_1, 'STL');
            }
            if (!isEmpty(action.order.stl_2)) {
                stl2 = prepareImage(action.order.stl_2, 'STL');
            }
            if (!isEmpty(action.order.stl_3)) {
                stl3 = prepareImage(action.order.stl_3, 'STL');
            }
            if (!isEmpty(action.order.stl_4)) {
                stl4 = prepareImage(action.order.stl_4, 'STL');
            }
            if (!isEmpty(action.order.stl_5)) {
                stl5 = prepareImage(action.order.stl_5, 'STL');
            }
            if (!isEmpty(action.order.stl_6)) {
                stl6 = prepareImage(action.order.stl_6, 'STL');
            }
            if (!isEmpty(action.order.stl_7)) {
                stl7 = prepareImage(action.order.stl_7, 'STL');
            }
            if (!isEmpty(action.order.stl_8)) {
                stl8 = prepareImage(action.order.stl_8, 'STL');
            }
            if (!isEmpty(action.order.stl_9)) {
                stl9 = prepareImage(action.order.stl_9, 'STL');
            }
            if (!isEmpty(action.order.stl_10)) {
                stl10 = prepareImage(action.order.stl_10, 'STL');
            }
            if (!isEmpty(action.order.stl_11)) {
                stl11 = prepareImage(action.order.stl_11, 'STL');
            }
            if (!isEmpty(action.order.stl_12)) {
                stl12 = prepareImage(action.order.stl_12, 'STL');
            }
            if (!isEmpty(action.order.stl_13)) {
                stl13 = prepareImage(action.order.stl_13, 'STL');
            }
            if (!isEmpty(action.order.stl_14)) {
                stl14 = prepareImage(action.order.stl_14, 'STL');
            }
            if (!isEmpty(action.order.stl_15)) {
                stl15 = prepareImage(action.order.stl_15, 'STL');
            }
            if (!isEmpty(action.order.stl_16)) {
                stl16 = prepareImage(action.order.stl_16, 'STL');
            }
            if (!isEmpty(action.order.stl_17)) {
                stl17 = prepareImage(action.order.stl_17, 'STL');
            }
            if (!isEmpty(action.order.stl_18)) {
                stl18 = prepareImage(action.order.stl_18, 'STL');
            }

            if (!isEmpty(action.order.stl_19)) {
                stl19 = prepareImage(action.order.stl_19, 'STL');
            }

            if (!isEmpty(action.order.stl_20)) {
                stl20 = prepareImage(action.order.stl_20, 'STL');
            }

            // template video
            if (!isEmpty(action.order.video)) {
                video = prepareImage(action.order.video, 'STL');
            }
            // equipment gif
            if (!isEmpty(action.order.gif)) {
                gif = prepareImage(action.order.gif, 'STL');
            }
            // equipment 2d gif
            if (!isEmpty(action.order.gif2d)) {
                gif2d = prepareImage(action.order.gif2d, 'STL');
            }
            // splint gif
            if (!isEmpty(action.order.gif_splint)) {
                gif_splint = prepareImage(action.order.gif_splint, 'STL');
            }
            // zip
            if (!isEmpty(action.order.zip)) {
                zip = prepareImage(action.order.zip, 'STL');
            }

            return {
                ...state,
                messageIsSending: false,
                formIsLoading: false,
                ...{
                    selectedOrder: {
                        ...action.order
                    },
                    newOrder: {
                        ...state.newOrder,
                        bill: action.order.bill,
                        bill_date: action.order.bill_date,
                        deadline: action.order.deadline,
                        organisation: action.order.organisation,
                        image_1: image1,
                        image_2: image2,
                        image_3: image3,
                        image_4: image4,
                        image_5: image5,
                        minivints_image_1: minivintsImage1,
                        minivints_image_2: minivintsImage2,
                        minivints_image_3: minivintsImage3,
                        minivints_image_4: minivintsImage4,
                        minivints_image_5: minivintsImage5,
                        minivints_image_6: minivintsImage6,
                        minivints_image_7: minivintsImage7,
                        minivints_image_8: minivintsImage8,
                        minivints_image_9: minivintsImage9,
                        minivints_image_10: minivintsImage10,
                        minivints_label_1: action.order.minivints_label_1,
                        minivints_label_2: action.order.minivints_label_2,
                        minivints_label_3: action.order.minivints_label_3,
                        minivints_label_4: action.order.minivints_label_4,
                        minivints_label_5: action.order.minivints_label_5,
                        minivints_label_6: action.order.minivints_label_6,
                        minivints_label_7: action.order.minivints_label_7,
                        minivints_label_8: action.order.minivints_label_8,
                        minivints_label_9: action.order.minivints_label_9,
                        minivints_label_10: action.order.minivints_label_10,
                        template_image_1: templateImage1,
                        template_image_2: templateImage2,
                        template_image_3: templateImage3,
                        template_image_4: templateImage4,
                        template_image_5: templateImage5,
                        template_image_6: templateImage6,
                        template_image_7: templateImage7,
                        template_image_8: templateImage8,
                        template_image_9: templateImage9,
                        template_image_10: templateImage10,
                        template_label_1: action.order.template_label_1,
                        template_label_2: action.order.template_label_2,
                        template_label_3: action.order.template_label_3,
                        template_label_4: action.order.template_label_4,
                        template_label_5: action.order.template_label_5,
                        template_label_6: action.order.template_label_6,
                        template_label_7: action.order.template_label_7,
                        template_label_8: action.order.template_label_8,
                        template_label_9: action.order.template_label_9,
                        template_label_10: action.order.template_label_10,
                        tool_image_1: toolImage1,
                        tool_image_2: toolImage2,
                        tool_image_3: toolImage3,
                        tool_image_4: toolImage4,
                        tool_image_5: toolImage5,
                        tool_image_6: toolImage6,
                        tool_image_7: toolImage7,
                        tool_image_8: toolImage8,
                        tool_image_9: toolImage9,
                        tool_image_10: toolImage10,
                        tool_label_1: action.order.tool_label_1,
                        tool_label_2: action.order.tool_label_2,
                        tool_label_3: action.order.tool_label_3,
                        tool_label_4: action.order.tool_label_4,
                        tool_label_5: action.order.tool_label_5,
                        tool_label_6: action.order.tool_label_6,
                        tool_label_7: action.order.tool_label_7,
                        tool_label_8: action.order.tool_label_8,
                        tool_label_9: action.order.tool_label_9,
                        tool_label_10: action.order.tool_label_10,
                        // tool 2d
                        tool_2d_image_1: tool2dImage1,
                        tool_2d_image_2: tool2dImage2,
                        tool_2d_image_3: tool2dImage3,
                        tool_2d_image_4: tool2dImage4,
                        tool_2d_image_5: tool2dImage5,
                        tool_2d_image_6: tool2dImage6,
                        tool_2d_image_7: tool2dImage7,
                        tool_2d_image_8: tool2dImage8,
                        tool_2d_image_9: tool2dImage9,
                        tool_2d_image_10: tool2dImage10,
                        tool_2d_label_1: action.order.tool_2d_label_1,
                        tool_2d_label_2: action.order.tool_2d_label_2,
                        tool_2d_label_3: action.order.tool_2d_label_3,
                        tool_2d_label_4: action.order.tool_2d_label_4,
                        tool_2d_label_5: action.order.tool_2d_label_5,
                        tool_2d_label_6: action.order.tool_2d_label_6,
                        tool_2d_label_7: action.order.tool_2d_label_7,
                        tool_2d_label_8: action.order.tool_2d_label_8,
                        tool_2d_label_9: action.order.tool_2d_label_9,
                        tool_2d_label_10: action.order.tool_2d_label_10,
                        // virtual setup
                        vs_image_1: vsImage1,
                        vs_image_2: vsImage2,
                        vs_image_3: vsImage3,
                        vs_image_4: vsImage4,
                        vs_image_5: vsImage5,
                        vs_image_6: vsImage6,
                        vs_image_7: vsImage7,
                        vs_image_8: vsImage8,
                        vs_image_9: vsImage9,
                        vs_image_10: vsImage10,
                        vs_image_11: vsImage11,
                        vs_image_12: vsImage12,
                        vs_image_13: vsImage13,
                        vs_image_14: vsImage14,
                        vs_image_15: vsImage15,
                        vs_label_1: action.order.vs_label_1,
                        vs_label_2: action.order.vs_label_2,
                        vs_label_3: action.order.vs_label_3,
                        vs_label_4: action.order.vs_label_4,
                        vs_label_5: action.order.vs_label_5,
                        vs_label_6: action.order.vs_label_6,
                        vs_label_7: action.order.vs_label_7,
                        vs_label_8: action.order.vs_label_8,
                        vs_label_9: action.order.vs_label_9,
                        vs_label_10: action.order.vs_label_10,
                        vs_label_11: action.order.vs_label_11,
                        vs_label_12: action.order.vs_label_12,
                        vs_label_13: action.order.vs_label_13,
                        vs_label_14: action.order.vs_label_14,
                        vs_label_15: action.order.vs_label_15,
                        // splint
                        splint_image_1: splintImage1,
                        splint_image_2: splintImage2,
                        splint_image_3: splintImage3,
                        splint_image_4: splintImage4,
                        splint_image_5: splintImage5,
                        splint_image_6: splintImage6,
                        splint_image_7: splintImage7,
                        splint_image_8: splintImage8,
                        splint_image_9: splintImage9,
                        splint_image_10: splintImage10,
                        splint_label_1: action.order.splint_label_1,
                        splint_label_2: action.order.splint_label_2,
                        splint_label_3: action.order.splint_label_3,
                        splint_label_4: action.order.splint_label_4,
                        splint_label_5: action.order.splint_label_5,
                        splint_label_6: action.order.splint_label_6,
                        splint_label_7: action.order.splint_label_7,
                        splint_label_8: action.order.splint_label_8,
                        splint_label_9: action.order.splint_label_9,
                        splint_label_10: action.order.splint_label_10,
                        // other
                        label_1: action.order.label_1,
                        label_2: action.order.label_2,
                        label_3: action.order.label_3,
                        label_4: action.order.label_4,
                        label_5: action.order.label_5,
                        added,
                        minivintsAdded,
                        templateAdded,
                        toolAdded,
                        tool2dAdded,
                        vsAdded,
                        splintAdded,
                        stl_1: stl1,
                        stl_2: stl2,
                        stl_3: stl3,
                        stl_4: stl4,
                        stl_5: stl5,
                        stl_6: stl6,
                        stl_7: stl7,
                        stl_8: stl8,
                        stl_9: stl9,
                        stl_10: stl10,
                        stl_11: stl11,
                        stl_12: stl12,
                        stl_13: stl13,
                        stl_14: stl14,
                        stl_15: stl15,
                        stl_16: stl16,
                        stl_17: stl17,
                        stl_18: stl18,
                        stl_19: stl19,
                        stl_20: stl20,
                        video,
                        gif,
                        gif2d,
                        gif_splint,
                        zip,
                        modelMode: zip && zip.length > 0 ? 'slices' : 'simple',
                        doctor: action.order.doctor,
                        technician: action.order.technician,
                        manager: action.order.manager,
                        patient: action.order.patient,
                        isPublic: action.order.isPublic,
                        clinic: action.order.doctor.company,
                        status: action.order.status,
                        status_minivints: action.order.status_minivints,
                        status_template: action.order.status_template,
                        status_equipment: action.order.status_equipment,
                        status_equipment_2d: action.order.status_equipment_2d,
                        status_braces: action.order.status_braces,
                        status_aligners: action.order.status_aligners,
                        status_tray: action.order.status_tray,
                        status_splint: action.order.status_splint,
                        version: action.order.version
                    }
                }
            };
        }
        case actionTypes.SELECT_ORDER: {
            let result = {
                ...state,
                messageIsSending: true,
                formIsLoading: true,
                ...{
                    selectedOrder: null
                }
            };
            if (!action.reload) {
                result = {
                    ...state,
                    messageIsSending: true
                };
            }
            return result;
        }
        case actionTypes.NEW_FORM_LABEL_CHANGED: {
            const newOrder = {
                ...state.newOrder
            };
            newOrder[`${action.obj}_${action.label}`] = action.value;
            return {
                ...state,
                ...{
                    newOrder
                }
            };
        }
        case actionTypes.CHANGE_MODEL_MODE:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        modelMode: action.modelMode
                    }
                }
            };
        case actionTypes.NEW_FORM_DOCTOR_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        doctor: action.doctor,
                        organisation: null
                    }
                }
            };
        case actionTypes.NEW_FORM_TECHNICIAN_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        technician: action.technician
                    }
                }
            };
        case actionTypes.NEW_FORM_MANAGER_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        manager: action.manager
                    }
                }
            };
        case actionTypes.NEW_FORM_ORG_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        organisation: action.organisation.name
                    }
                }
            };
        case actionTypes.NEW_FORM_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        added: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_MINIVINTS_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        minivintsAdded: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_TEMPLATE_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        templateAdded: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_TOOL_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        toolAdded: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_TOOL_2D_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        tool2dAdded: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_VS_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        vsAdded: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_SPLINT_ADDED_CHANGED:
            return {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        splintAdded: action.added
                    }
                }
            };
        case actionTypes.NEW_FORM_STATUS_CHANGED: {
            const { status, value } = action;
            let newState = {
                ...state,
                ...{
                    newOrder: {
                        ...state.newOrder,
                        isOpen: true
                    }
                }
            };
            if (status === 'minivints') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_minivints: value
                        }
                    }
                };
            } else if (status === 'template') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_template: value
                        }
                    }
                };
            } else if (status === 'equipment') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_equipment: value
                        }
                    }
                };
            } else if (status === 'equipment_2d') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_equipment_2d: value
                        }
                    }
                };
            } else if (status === 'braces') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_braces: value
                        }
                    }
                };
            } else if (status === 'aligners') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_aligners: value
                        }
                    }
                };
            } else if (status === 'tray') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_tray: value
                        }
                    }
                };
            } else if (status === 'splint') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status_splint: value
                        }
                    }
                };
            } else if (status === 'status') {
                newState = {
                    ...state,
                    ...{
                        newOrder: {
                            ...state.newOrder,
                            status: value
                        }
                    }
                };
            }

            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_FILE_UPLOAD_STATUS: {
            const { index } = action;
            const newState = { ...state };
            newState.newOrder[`stl_${index}`] = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_VIDEO_UPLOAD_STATUS: {
            const newState = { ...state };
            newState.newOrder.video = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_GIF_UPLOAD_STATUS: {
            const newState = { ...state };
            newState.newOrder.gif = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_GIF_2D_UPLOAD_STATUS: {
            const newState = { ...state };
            newState.newOrder.gif2d = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_GIF_SPLINT_UPLOAD_STATUS: {
            const newState = { ...state };
            newState.newOrder.gif_splint = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_ZIP_UPLOAD_STATUS: {
            const newState = { ...state };
            newState.newOrder.zip = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.CHANGE_NEW_ORDER_UPLOAD_STATUS: {
            const { index, obj } = action;
            const newState = { ...state };
            newState.newOrder[`${obj}_image_${index}`] = action.fileList;
            newState.newOrder.isUploading = action.status;
            return newState;
        }
        case actionTypes.OPEN_NEW_ORDER: {
            const formIsLoading = !isEmpty(action.orderId);
            return {
                ...state,
                versionIncrement: action.versionIncrement,
                ...{
                    formIsLoading,
                    newOrder: {
                        structureDefault: [],
                        isOpen: true,
                        isCreateOpen: false,
                        orderId: action.orderId,
                        bill: '',
                        organisation: null,
                        isPublic: false,
                        image_1: [],
                        image_2: [],
                        image_3: [],
                        image_4: [],
                        image_5: [],
                        image_6: [],
                        image_7: [],
                        image_8: [],
                        image_9: [],
                        image_10: [],
                        // minivints
                        minivints_image_1: [],
                        minivints_image_2: [],
                        minivints_image_3: [],
                        minivints_image_4: [],
                        minivints_image_5: [],
                        minivints_image_6: [],
                        minivints_image_7: [],
                        minivints_image_8: [],
                        minivints_image_9: [],
                        minivints_image_10: [],
                        // template
                        template_image_1: [],
                        template_image_2: [],
                        template_image_3: [],
                        template_image_4: [],
                        template_image_5: [],
                        template_image_6: [],
                        template_image_7: [],
                        template_image_8: [],
                        template_image_9: [],
                        template_image_10: [],
                        // tool
                        tool_image_1: [],
                        tool_image_2: [],
                        tool_image_3: [],
                        tool_image_4: [],
                        tool_image_5: [],
                        tool_image_6: [],
                        tool_image_7: [],
                        tool_image_8: [],
                        tool_image_9: [],
                        tool_image_10: [],
                        // tool 2d
                        tool_2d_image_1: [],
                        tool_2d_image_2: [],
                        tool_2d_image_3: [],
                        tool_2d_image_4: [],
                        tool_2d_image_5: [],
                        tool_2d_image_6: [],
                        tool_2d_image_7: [],
                        tool_2d_image_8: [],
                        tool_2d_image_9: [],
                        tool_2d_image_10: [],
                        // vs
                        vs_image_1: [],
                        vs_image_2: [],
                        vs_image_3: [],
                        vs_image_4: [],
                        vs_image_5: [],
                        vs_image_6: [],
                        vs_image_7: [],
                        vs_image_8: [],
                        vs_image_9: [],
                        vs_image_10: [],
                        vs_image_11: [],
                        vs_image_12: [],
                        vs_image_13: [],
                        vs_image_14: [],
                        vs_image_15: [],
                        // splint
                        splint_image_1: [],
                        splint_image_2: [],
                        splint_image_3: [],
                        splint_image_4: [],
                        splint_image_5: [],
                        splint_image_6: [],
                        splint_image_7: [],
                        splint_image_8: [],
                        splint_image_9: [],
                        splint_image_10: [],
                        // labels
                        label_1: '#1',
                        label_2: '#2',
                        label_3: '#3',
                        label_4: '#4',
                        label_5: '#5',
                        stl_1: [],
                        stl_2: [],
                        stl_3: [],
                        stl_4: [],
                        stl_5: [],
                        stl_6: [],
                        stl_7: [],
                        stl_8: [],
                        stl_9: [],
                        stl_10: [],
                        stl_11: [],
                        stl_12: [],
                        stl_13: [],
                        stl_14: [],
                        stl_15: [],
                        stl_16: [],
                        stl_17: [],
                        stl_18: [],
                        stl_19: [],
                        stl_20: [],
                        video: [],
                        gif: [],
                        gif2d: [],
                        gif_splint: [],
                        zip: [],
                        modelMode: 'simple',
                        patient: '',
                        added: 1,
                        search: '',
                        doctor: null,
                        clinic: null,
                        status: 'NEW',
                        status_minivints: 'NONE',
                        status_template: 'NONE',
                        status_equipment: 'NONE',
                        status_equipment_2d: 'NONE',
                        status_braces: 'NONE',
                        status_aligners: 'NONE',
                        status_tray: 'NONE',
                        status_splint: 'NONE',
                        version: null
                    }
                }
            };
        }
        case actionTypes.OPEN_CREATE_ORDER: {
            const formIsLoading = !isEmpty(action.orderId);
            return {
                ...state,
                ...{
                    formIsLoading,
                    newOrder: {
                        structureDefault: [],
                        isOpen: false,
                        isCreateOpen: true,
                        orderId: action.orderId,
                        bill: '',
                        organisation: null,
                        isPublic: true,
                        image_1: [],
                        image_2: [],
                        image_3: [],
                        image_4: [],
                        image_5: [],
                        image_6: [],
                        image_7: [],
                        image_8: [],
                        image_9: [],
                        image_10: [],
                        label_1: '#1',
                        label_2: '#2',
                        label_3: '#3',
                        label_4: '#4',
                        label_5: '#5',
                        stl_1: [],
                        stl_2: [],
                        stl_3: [],
                        stl_4: [],
                        stl_5: [],
                        stl_6: [],
                        stl_7: [],
                        stl_8: [],
                        stl_9: [],
                        stl_10: [],
                        stl_11: [],
                        stl_12: [],
                        stl_13: [],
                        stl_14: [],
                        stl_15: [],
                        stl_16: [],
                        stl_17: [],
                        stl_18: [],
                        stl_19: [],
                        stl_20: [],
                        video: [],
                        gif: [],
                        gif2d: [],
                        gif_splint: [],
                        zip: [],
                        modelMode: 'simple',
                        patient: '',
                        added: 1,
                        search: '',
                        doctor: null,
                        clinic: null,
                        status: 'NEW',
                        status_minivints: 'NONE',
                        status_template: 'NONE',
                        status_equipment: 'NONE',
                        status_equipment_2d: 'NONE',
                        status_braces: 'NONE',
                        status_aligners: 'NONE',
                        status_tray: 'NONE',
                        status_splint: 'NONE',
                        version: null
                    }
                }
            };
        }
        case actionTypes.CLOSE_NEW_ORDER:
            return {
                ...state,
                newOrder: { ...primaryInitialState.newOrder }
                // ...{
                //     newOrder: {
                //         ...primaryInitialState.newOrder,
                //         isOpen: false,
                //         isCreateOpen: false
                //     }
                // }
            };
        default:
            return state;
    }
};

export default OrderReducer;
