import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import qs from 'qs';
import {
    Button,
    Checkbox,
    Col,
    Layout,
    Radio,
    Row,
    Spin,
    Divider,
    message,
    Slider,
    Switch
} from 'antd';
import styled from 'styled-components';
import { orderBy, find } from 'lodash';
// import Head from 'next/head';
import { selectOrder, onSaveStlSettings, loadLocale } from '../redux/actions';
import Shape from '../components/Order/OrderStlFiber';
import { OrderStlViewSettings } from '../components/Order/OrderStlViewSettings';
import { UpperJawIcon } from '../components/Icon/UpperJawIcon';
import { LowerJawIcon } from '../components/Icon/LowerJawIcon';
import { SliceSlider } from '../components/Order/SliceSlider';
import { isEmpty } from '../utils/common';

const { Content } = Layout;

const RadioThemed = styled(Radio.Group)`
    .ant-radio-wrapper {
        color: ${(props) => (props.theme === 'dark' ? '#ffffff' : '#000000a6')};
    }
`;

const SliderThemed = styled(Slider)`
    .ant-slider-mark-text {
        color: ${(props) =>
            props.theme === 'dark' ? '#ffffff87' : '#000000a6'};

        &.ant-slider-mark-text-active {
            color: ${(props) =>
                props.theme === 'dark' ? '#ffffff' : '#000000a6'};
        }
    }
`;

const ContentStyled = styled(Content)`
    padding: 16px;
    background: #fff;
    max-width: 900px;
    border-radius: 4px;
`;

const ContentSpinStyled = styled(Content)`
    padding: 16px;
    margin: 128px auto;
    max-width: 900px;
    border-radius: 4px;
`;

const RowStyled = styled(Row)`
    /* margin-bottom: 36px; */
`;

const ParamsTitle = styled.div`
    margin-top: 8px;
    font-weight: 500;
`;

const ContentLayout = styled(Layout)`
    background: #fff;
`;

const ModelUpperPanel = styled.div`
    display: inline-block;
    vertical-align: top;

    @media (max-width: 1108px) {
        display: none;
    }
`;
const ModelBottomPanel = styled.div`
    display: none;

    @media (max-width: 1108px) {
        display: block;
    }
`;

const ThemeSwitch = styled(Switch)`
    .ant-switch-inner {
        font-size: 16px;
    }
`;

const ModelLowerButon = styled(Button)`
    height: 64px !important;
    display: inline-block !important;
    margin-left: 28px !important;
    vertical-align: top !important;

    @media (max-width: 1108px) {
        display: none !important;
    }
`;

const MaxillaryUpperPanel = styled.div`
    display: inline-block;
    vertical-align: top;
    @media (max-width: 1108px) {
        display: none !important;
    }
`;
const MaxillaryBottomPanel = styled.div`
    display: none;

    @media (max-width: 1108px) {
        display: block;
    }
`;

export const OrderPage = () => {
    const location = useLocation();
    const params = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const {
        id: idFromQuery,
        revision: revisionFromQuery,
        locale: localeFromQuery,
        theme: themeFromQuery,
        token: tokenFromQuery
    } = params;
    const dispatch = useDispatch();
    const order = useSelector((state: any) => state.OrderReducer.selectedOrder);
    const stlSettings = useSelector(
        (state: any) => state.ProfileReducer.profile.stl
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const [revision, setRevision] = useState(revisionFromQuery);
    const [locale, setLocale] = useState(localeFromQuery || 'ru');
    const [themeViewer, setThemeViewer] = useState(themeFromQuery || 'light');
    const [slicesCompletelyLoaded, setSlicesCompletelyLoaded] = useState(false);
    const [id, setId] = useState(idFromQuery);
    // const [sceneView, setSceneView] = useState([
    //     -Math.PI / 2,
    //     Math.PI,
    //     Math.PI
    // ]);
    const [sceneView, setSceneView] = useState([0, 0, 0]);
    const [sliceIndex, setSliceIndex] = useState(0);
    const [showOverLayer, setShowOverLayer] = useState(false);

    // const [bordersVisible, setBordersVisible] = useState(false);
    // const [modelVisible, setModelVisible] = useState(false);
    // const [minivintsVisible, setMinivintsVisible] = useState(false);
    // const [toolVisible, setToolVisible] = useState(false);
    // const [templateVisible, setTemplateVisible] = useState(false);
    // const [trayVisible, setTrayVisible] = useState(false);
    // const [mode, setMode] = useState('tool');
    const [shape, setShape] = useState({
        before: true,
        after: false,
        upper: true,
        lower: true,
        maxillaryUpper: undefined,
        maxillaryLower: undefined,
        maxillaryUpperAfter: undefined,
        maxillaryLowerAfter: undefined,
        maxillaryVisible: true,
        maxillaryColor: stlSettings
            ? stlSettings.maxillary_color
                ? stlSettings.maxillary_color
                : '#e8a0a0'
            : '#e8a0a0',
        maxillaryShineness: stlSettings
            ? stlSettings.maxillary_shineness
                ? stlSettings.maxillary_shineness
                : 60
            : 60,
        maxillaryOpacity: stlSettings
            ? stlSettings.maxillary_opacity
                ? stlSettings.maxillary_opacity
                : 100
            : 100,
        // model
        model: undefined,
        modelVisible: true,
        modelColor: stlSettings ? stlSettings.model_color : '#e6bf8b',
        modelAfterColor: stlSettings
            ? stlSettings.model_after_color
                ? stlSettings.model_after_color
                : '#f6f5e8'
            : '#f6f5e8',
        modelShineness: stlSettings ? stlSettings.model_shineness : 15,
        modelAfterShineness: stlSettings
            ? stlSettings.model_after_shineness
                ? stlSettings.model_after_shineness
                : 20
            : 20,
        extra: undefined,
        extraVisible: true,
        extraColor: stlSettings
            ? stlSettings.extra_color
                ? stlSettings.extra_color
                : '#696d6c'
            : '#696d6c',
        extraShineness: stlSettings
            ? stlSettings.model_shineness
                ? stlSettings.model_shineness
                : 100
            : 100,
        minivints: undefined,
        minivintsVisible: true,
        minivintsColor: stlSettings ? stlSettings.minivints_color : '#009a63',
        minivintsShineness: stlSettings ? stlSettings.minivints_shineness : 100,
        tool: undefined,
        toolVisible: true,
        toolColor: stlSettings ? stlSettings.tool_color : '#ffffff',
        toolShineness: stlSettings ? stlSettings.tool_shineness : 55,
        // splint
        splint: undefined,
        splintVisible: true,
        splintColor: stlSettings
            ? stlSettings.splint_color
                ? stlSettings.splint_color
                : '#aeccdc'
            : '#aeccdc',
        splintShineness: stlSettings ? stlSettings.splint_shineness : 100,
        splintOpacity: stlSettings ? stlSettings.splint_opacity : 70,
        // braces
        bracesUpper: undefined,
        bracesLower: undefined,
        bracesVisible: false,
        bracesColor: stlSettings ? stlSettings.braces_color : '#ffffff',
        bracesShineness: stlSettings ? stlSettings.braces_shineness : 50,
        // template
        template: undefined,
        templateVisible: false,
        templateColor: stlSettings ? stlSettings.template_color : '#e6e1d6',
        templateShineness: stlSettings ? stlSettings.template_shineness : 20,
        tray: undefined,
        trayVisible: true,
        trayColor: stlSettings ? stlSettings.tray_color : '#ffffff',
        trayShineness: stlSettings ? stlSettings.tray_shineness : 100,
        trayOpacity: stlSettings ? stlSettings.tray_opacity : 70,
        bordersVisible: false,
        mode: 'tool'
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const newParams = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setId(newParams.id);
        setRevision(newParams.revision);
    }, [location]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dispatch(selectOrder(id, revision, true, tokenFromQuery));
    }, [id, revision, dispatch]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dispatch(loadLocale(locale, tokenFromQuery));
    }, [locale, dispatch]);

    const onChangeModelUpper = () => {
        const newShape = { ...shape };
        newShape.upper = !newShape.upper;
        setShape(newShape);
    };

    const onChangeModelLower = () => {
        const newShape = { ...shape };
        newShape.lower = !newShape.lower;
        setShape(newShape);
    };

    const onChangeMinivints = (e: any) => {
        const newShape = { ...shape };
        newShape.minivintsVisible = e.target.checked;
        if (newShape.minivintsVisible) {
            newShape.after = false;
            newShape.before = true;
        }
        setShape(newShape);
    };

    const onChangeBefore = (e: any) => {
        const newShape = { ...shape };
        newShape.before = e.target.checked;
        setShape(newShape);
    };
    const onChangeAfter = (e: any) => {
        const newShape = { ...shape };
        newShape.after = e.target.checked;
        if (
            (!newShape.after && order.status_braces !== 'NONE') ||
            order.status_aligners !== 'NONE'
        ) {
            newShape.mode = 'none';
            // newShape.after = false;
            // newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        }
        setShape(newShape);
    };

    const onChangeExtra = (e: any) => {
        const newShape = { ...shape };
        newShape.extraVisible = e.target.checked;
        setShape(newShape);
    };

    const onChangeMode = (e: any) => {
        const newShape = { ...shape };
        const currentOrder = order;
        if (e.target.value === 'tool') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = true;
            newShape.trayVisible = true;
            newShape.splintVisible = false;
        } else if (e.target.value === 'splint') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = true;
        } else if (e.target.value === 'template') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = true;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        } else if (e.target.value === 'braces') {
            newShape.mode = e.target.value;
            newShape.after = true;
            newShape.before = false;
            newShape.bracesVisible = true;
            newShape.templateVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        } else {
            newShape.mode = e.target.value;
            // newShape.after = false;
            // newShape.before = true;
            if (
                currentOrder.status_braces !== 'NONE' ||
                currentOrder.status_aligners !== 'NONE'
            ) {
                newShape.after = true;
                newShape.before = false;
            }

            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        }
        setShape(newShape);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (order) {
            const orderedVersions = orderBy(
                order.orderversions,
                ['design_date'],
                ['desc']
            );
            const orderVersion =
                revision === 'latest'
                    ? orderedVersions[0]
                    : find(orderedVersions, (o) => `${o.id}` === revision);
            // equipment
            const stl3 = isEmpty(orderVersion.stl_3)
                ? ''
                : orderVersion.stl_3.rawUrl;
            // equipment
            const stl4 = isEmpty(orderVersion.stl_4)
                ? ''
                : orderVersion.stl_4.rawUrl;
            // equipment
            const stl18 = isEmpty(orderVersion.stl_18)
                ? ''
                : orderVersion.stl_18.rawUrl;
            // equipment
            const stl19 = isEmpty(orderVersion.stl_19)
                ? ''
                : orderVersion.stl_19.rawUrl;
            // equipment
            const stl20 = isEmpty(orderVersion.stl_20)
                ? ''
                : orderVersion.stl_20.rawUrl;
            let result = 'none';
            if (stl3 !== '') {
                result = 'tool';
            } else if (stl4 !== '') {
                result = 'template';
            } else if (stl18 !== '' || stl19 !== '') {
                result = 'braces';
            } else if (stl20 !== '') {
                result = 'splint';
            }
            onChangeMode({ target: { value: result } });
        }
    }, [order]);

    const onChangeColor = (what: string, color: string) => {
        const newShape = { ...shape };
        if (what === 'model') {
            newShape.modelColor = color;
        } else if (what === 'modelAfter') {
            newShape.modelAfterColor = color;
        } else if (what === 'template') {
            newShape.templateColor = color;
        } else if (what === 'minivints') {
            newShape.minivintsColor = color;
        } else if (what === 'tray') {
            newShape.trayColor = color;
        } else if (what === 'tool') {
            newShape.toolColor = color;
        } else if (what === 'splint') {
            newShape.splintColor = color;
        } else if (what === 'maxillary') {
            newShape.maxillaryColor = color;
        }
        setShape(newShape);
    };

    const onDefault = (
        newModelColor: string,
        newModelAfterColor: string,
        newMinivintsColor: string,
        newTemplateColor: string,
        newToolColor: string,
        newTrayColor: string,
        newSplintColor: string,
        newMaxillaryColor: string,
        newModelShineness: number,
        newModelAfterShineness: number,
        newMinivintsShineness: number,
        newTemplateShineness: number,
        newToolShineness: number,
        newTrayShineness: number,
        newTrayOpacity: number,
        newSplintShineness: number,
        newSplintOpacity: number,
        newMaxillaryShineness: number
    ) => {
        const newShape = { ...shape };
        newShape.modelColor = newModelColor;
        newShape.modelAfterColor = newModelAfterColor;
        newShape.modelShineness = newModelShineness;
        newShape.modelAfterShineness = newModelAfterShineness;
        newShape.minivintsColor = newMinivintsColor;
        newShape.minivintsShineness = newMinivintsShineness;
        newShape.templateColor = newTemplateColor;
        newShape.templateShineness = newTemplateShineness;
        newShape.toolColor = newToolColor;
        newShape.toolShineness = newToolShineness;
        newShape.trayColor = newTrayColor;
        newShape.trayShineness = newTrayShineness;
        newShape.trayOpacity = newTrayOpacity;
        // Splint
        newShape.splintColor = newSplintColor;
        newShape.splintShineness = newSplintShineness;
        newShape.splintOpacity = newSplintOpacity;
        // Maxillary
        newShape.maxillaryColor = newMaxillaryColor;
        newShape.maxillaryShineness = newMaxillaryShineness;
        setShape(newShape);
    };

    const onChangeShineness = (what: string, shineness: number) => {
        const newShape = { ...shape };
        if (what === 'model') {
            newShape.modelShineness = shineness;
        } else if (what === 'modelAfter') {
            newShape.modelAfterShineness = shineness;
        } else if (what === 'template') {
            newShape.templateShineness = shineness;
        } else if (what === 'minivints') {
            newShape.minivintsShineness = shineness;
        } else if (what === 'tray') {
            newShape.trayShineness = shineness;
        } else if (what === 'tool') {
            newShape.toolShineness = shineness;
        } else if (what === 'splint') {
            newShape.splintShineness = shineness;
        } else if (what === 'maxillary') {
            newShape.maxillaryShineness = shineness;
        }
        setShape(newShape);
    };

    const onChangeOpacity = (what: string, opacity: number) => {
        const newShape = { ...shape };
        if (what === 'model') {
            // newShape.modelOpacity = opacity;
        } else if (what === 'template') {
            // newShape.templateOpacity = opacity;
        } else if (what === 'minivints') {
            // newShape.minivintsOpacity = opacity;
        } else if (what === 'tray') {
            newShape.trayOpacity = opacity;
        } else if (what === 'tool') {
            // newShape.toolOpacity = opacity;
        } else if (what === 'splint') {
            newShape.splintOpacity = opacity;
        } else if (what === 'maxillary') {
            newShape.maxillaryOpacity = opacity;
        }
        setShape(newShape);
    };

    let content = (
        <Layout
            style={{
                background: themeFromQuery === 'dark' ? '#222b45' : '#ffffff'
            }}
        >
            <ContentSpinStyled>
                <Spin
                    size="large"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            </ContentSpinStyled>
        </Layout>
    );
    if (order && texts) {
        const orderedVersions = orderBy(
            order.orderversions,
            ['design_date'],
            ['desc']
        );
        const orderVersion =
            revision === 'latest'
                ? orderedVersions[0]
                : find(orderedVersions, (o) => `${o.id}` === revision);
        // equipment gif splint
        // eslint-disable-next-line
        const gif_splint = isEmpty(orderVersion.gif_splint)
            ? ''
            : orderVersion.gif_splint.rawUrl;
        const zip = isEmpty(orderVersion.zip) ? '' : orderVersion.zip.rawUrl;
        const { slices } = orderVersion;
        const isSlicesAvailable = !isEmpty(slices);
        // model
        const stl1 = isEmpty(orderVersion.stl_1)
            ? ''
            : orderVersion.stl_1.rawUrl;
        // minivints
        const stl2 = isEmpty(orderVersion.stl_2)
            ? ''
            : orderVersion.stl_2.rawUrl;
        // equipment
        const stl3 = isEmpty(orderVersion.stl_3)
            ? ''
            : orderVersion.stl_3.rawUrl;
        // template
        const stl4 = isEmpty(orderVersion.stl_4)
            ? ''
            : orderVersion.stl_4.rawUrl;
        // tray
        const stl5 = isEmpty(orderVersion.stl_5)
            ? ''
            : orderVersion.stl_5.rawUrl;
        // addition
        const stl6 = isEmpty(orderVersion.stl_6)
            ? ''
            : orderVersion.stl_6.rawUrl;
        // upper jaw model after
        const stl7 = isEmpty(orderVersion.stl_7)
            ? ''
            : orderVersion.stl_7.rawUrl;
        // lower jaw model before
        const stl8 = isEmpty(orderVersion.stl_8)
            ? ''
            : orderVersion.stl_8.rawUrl;
        // lower jaw model after
        const stl9 = isEmpty(orderVersion.stl_9)
            ? ''
            : orderVersion.stl_9.rawUrl;
        // upper maxillary model before
        const stl10 = isEmpty(orderVersion.stl_10)
            ? ''
            : orderVersion.stl_10.rawUrl;
        // upper maxillary model after
        const stl11 = isEmpty(orderVersion.stl_11)
            ? ''
            : orderVersion.stl_11.rawUrl;
        // lower maxillary model before
        const stl12 = isEmpty(orderVersion.stl_12)
            ? ''
            : orderVersion.stl_12.rawUrl;
        // lower maxillary model after
        const stl13 = isEmpty(orderVersion.stl_13)
            ? ''
            : orderVersion.stl_13.rawUrl;
        // upper jaw braces
        const stl18 = isEmpty(orderVersion.stl_18)
            ? ''
            : orderVersion.stl_18.rawUrl;
        // lower jaw braces
        const stl19 = isEmpty(orderVersion.stl_19)
            ? ''
            : orderVersion.stl_19.rawUrl;
        // splint
        const stl20 = isEmpty(orderVersion.stl_20)
            ? ''
            : orderVersion.stl_20.rawUrl;

        const options = [
            {
                label: texts.equipment.value,
                value: 'tool',
                disabled: stl3 === ''
            },
            {
                label: texts.template.value,
                value: 'template',
                disabled: stl4 === ''
            },
            {
                label: texts.splint.value,
                value: 'splint',
                disabled: stl20 === ''
            },
            // { label: texts.equipment.value, value: 'tool' },
            {
                label: isSlicesAvailable
                    ? texts.attachments.value
                    : texts.braces.value,
                value: 'braces',
                disabled: stl18 === '' && stl19 === '' && !isSlicesAvailable
            },
            // { label: texts.template.value, value: 'template' },
            { label: texts.none.value, value: 'none' }
        ];

        const getDefaultSelectedModel = (
            tool: string,
            template: string,
            bracesUpper: string,
            bracesLower: string,
            splint: string
        ) => {
            let result = 'none';
            if (tool !== '') {
                result = 'tool';
            } else if (template !== '') {
                result = 'template';
            } else if (bracesUpper !== '' || bracesLower !== '') {
                result = 'braces';
            } else if (splint !== '') {
                result = 'splint';
            }

            return result;
        };

        // const modeValue = shape.mode;
        let stlViewer;
        if (
            stl1 !== '' ||
            stl2 !== '' ||
            stl3 !== '' ||
            stl4 !== '' ||
            stl5 !== '' ||
            stl6 !== '' ||
            stl18 !== '' ||
            stl19 !== '' ||
            stl20 !== '' ||
            isSlicesAvailable
        ) {
            stlViewer = (
                <RowStyled gutter={16}>
                    <Col md={24} sm={24}>
                        <RowStyled
                            gutter={16}
                            style={{ padding: '32px 0 16px' }}
                        >
                            <Col md={24} sm={24} xs={24}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top'
                                        // minWidth: '515px'
                                    }}
                                >
                                    <ParamsTitle>
                                        {texts['order.3d.object'].value}:
                                    </ParamsTitle>
                                    <RadioThemed
                                        theme={themeFromQuery}
                                        style={{
                                            maxWidth: 300
                                        }}
                                        options={options}
                                        onChange={onChangeMode}
                                        defaultValue={getDefaultSelectedModel(
                                            stl3,
                                            stl4,
                                            stl18,
                                            stl19,
                                            stl20
                                        )}
                                        value={shape.mode}
                                    />
                                </div>
                                <ModelUpperPanel>
                                    <ParamsTitle
                                        style={{ textAlign: 'center' }}
                                    >
                                        {texts.model.value}:
                                    </ParamsTitle>
                                    <Button
                                        disabled={
                                            stl1 === '' &&
                                            stl7 === '' &&
                                            !isSlicesAvailable
                                        }
                                        style={{ height: 64, marginLeft: 28 }}
                                        type={
                                            shape.upper ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelUpper}
                                    >
                                        <UpperJawIcon
                                            style={{
                                                color: shape.upper
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.upper_jaw.value}</div>
                                    </Button>
                                </ModelUpperPanel>
                                <div style={{ marginTop: 4 }}>
                                    <div
                                        style={{
                                            display: 'inline-block'
                                            // minWidth: '515px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'inline-block'
                                            }}
                                        >
                                            <ParamsTitle>
                                                {
                                                    texts['order.3d.parameters']
                                                        .value
                                                }
                                                :
                                            </ParamsTitle>
                                            <div style={{ marginTop: 10 }}>
                                                <Checkbox
                                                    style={{
                                                        color:
                                                            themeFromQuery ===
                                                            'dark'
                                                                ? '#ffffff'
                                                                : '#000000a6'
                                                    }}
                                                    disabled={stl2 === ''}
                                                    checked={
                                                        stl2 === ''
                                                            ? false
                                                            : shape.minivintsVisible
                                                    }
                                                    onChange={onChangeMinivints}
                                                >
                                                    {texts.minivints.value}
                                                </Checkbox>
                                                <Checkbox
                                                    style={{
                                                        color:
                                                            themeFromQuery ===
                                                            'dark'
                                                                ? '#ffffff'
                                                                : '#000000a6'
                                                    }}
                                                    disabled={stl6 === ''}
                                                    checked={
                                                        stl6 === ''
                                                            ? false
                                                            : shape.extraVisible
                                                    }
                                                    onChange={onChangeExtra}
                                                >
                                                    {texts.addition.value}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    style={{
                                                        width: 111,
                                                        color:
                                                            themeFromQuery ===
                                                            'dark'
                                                                ? '#ffffff'
                                                                : '#000000a6'
                                                    }}
                                                    onChange={onChangeBefore}
                                                    checked={shape.before}
                                                    disabled={isSlicesAvailable}
                                                >
                                                    {texts.before.value}
                                                </Checkbox>
                                                <Checkbox
                                                    style={{
                                                        color:
                                                            themeFromQuery ===
                                                            'dark'
                                                                ? '#ffffff'
                                                                : '#000000a6'
                                                    }}
                                                    disabled={
                                                        stl7 === '' &&
                                                        stl9 === '' &&
                                                        stl11 === '' &&
                                                        stl13 === '' &&
                                                        stl19 === ''
                                                    }
                                                    onChange={onChangeAfter}
                                                    checked={shape.after}
                                                >
                                                    {texts.after.value}
                                                </Checkbox>
                                            </div>
                                        </div>
                                        <MaxillaryUpperPanel
                                            style={{
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                width: 180
                                            }}
                                        >
                                            <ParamsTitle
                                                style={{
                                                    display:
                                                        stl10 === '' &&
                                                        stl11 === '' &&
                                                        !isSlicesAvailable &&
                                                        stl12 === '' &&
                                                        stl13 === ''
                                                            ? 'none'
                                                            : 'block'
                                                }}
                                            >
                                                {
                                                    texts[
                                                        'stl.maxillary.opacity'
                                                    ].value
                                                }
                                                :
                                            </ParamsTitle>
                                            <SliderThemed
                                                marks={{ 0: '0', 100: '100' }}
                                                theme={themeFromQuery}
                                                style={{
                                                    display:
                                                        stl10 === '' &&
                                                        stl11 === '' &&
                                                        !isSlicesAvailable &&
                                                        stl12 === '' &&
                                                        stl13 === ''
                                                            ? 'none'
                                                            : 'block'
                                                }}
                                                step={5}
                                                value={shape.maxillaryOpacity}
                                                onChange={(value: any) =>
                                                    onChangeOpacity(
                                                        'maxillary',
                                                        value
                                                    )
                                                }
                                            />
                                        </MaxillaryUpperPanel>
                                    </div>
                                    <ModelLowerButon
                                        disabled={
                                            stl8 === '' &&
                                            stl9 === '' &&
                                            !isSlicesAvailable
                                        }
                                        type={
                                            shape.lower ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelLower}
                                    >
                                        <LowerJawIcon
                                            style={{
                                                color: shape.lower
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.lower_jaw.value}</div>
                                    </ModelLowerButon>
                                </div>
                                <ModelBottomPanel>
                                    <MaxillaryBottomPanel
                                        style={{
                                            display: 'block',
                                            verticalAlign: 'top',
                                            width: 180
                                        }}
                                    >
                                        <ParamsTitle
                                            style={{
                                                display:
                                                    stl10 === '' &&
                                                    stl11 === '' &&
                                                    stl12 === '' &&
                                                    !isSlicesAvailable &&
                                                    stl13 === ''
                                                        ? 'none'
                                                        : 'block'
                                            }}
                                        >
                                            {
                                                texts['stl.maxillary.opacity']
                                                    .value
                                            }
                                            :
                                        </ParamsTitle>
                                        <SliderThemed
                                            theme={themeFromQuery}
                                            marks={{ 0: '0', 100: '100' }}
                                            style={{
                                                display:
                                                    stl10 === '' &&
                                                    stl11 === '' &&
                                                    stl12 === '' &&
                                                    !isSlicesAvailable &&
                                                    stl13 === ''
                                                        ? 'none'
                                                        : 'block'
                                            }}
                                            step={5}
                                            value={shape.maxillaryOpacity}
                                            onChange={(value: any) =>
                                                onChangeOpacity(
                                                    'maxillary',
                                                    value
                                                )
                                            }
                                        />
                                    </MaxillaryBottomPanel>
                                    <ParamsTitle>
                                        {texts.model.value}:
                                    </ParamsTitle>
                                    <Button
                                        disabled={
                                            stl1 === '' &&
                                            stl7 === '' &&
                                            !isSlicesAvailable
                                        }
                                        style={{ height: 64 }}
                                        type={
                                            shape.upper ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelUpper}
                                    >
                                        <UpperJawIcon
                                            style={{
                                                color: shape.upper
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.upper_jaw.value}</div>
                                    </Button>
                                    <Button
                                        style={{
                                            height: 64,
                                            display: 'inline-block',
                                            marginLeft: 16,
                                            verticalAlign: 'top'
                                        }}
                                        disabled={
                                            stl8 === '' &&
                                            stl9 === '' &&
                                            !isSlicesAvailable
                                        }
                                        type={
                                            shape.lower ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelLower}
                                    >
                                        <LowerJawIcon
                                            style={{
                                                color: shape.lower
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.lower_jaw.value}</div>
                                    </Button>
                                </ModelBottomPanel>
                            </Col>
                            <Col
                                md={24}
                                sm={24}
                                xs={24}
                                // hidden={!isSlicesAvailable}
                            >
                                <div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            minWidth: '300px'
                                        }}
                                    >
                                        <ParamsTitle>{`${texts['view.title'].value}:`}</ParamsTitle>
                                        <Button.Group size="small">
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        Math.PI / 2,
                                                        Math.PI,
                                                        Math.PI
                                                    ]);
                                                }}
                                            >
                                                {texts['view.top'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        0,
                                                        Math.PI / 2,
                                                        0
                                                    ]);
                                                }}
                                            >
                                                {texts['view.right'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([0, 0, 0]);
                                                }}
                                            >
                                                {texts['view.front'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        0,
                                                        -Math.PI / 2,
                                                        0
                                                    ]);
                                                }}
                                            >
                                                {texts['view.left'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        -Math.PI / 2,
                                                        Math.PI,
                                                        Math.PI
                                                    ]);
                                                }}
                                            >
                                                {texts['view.bottom'].value}
                                            </Button>
                                        </Button.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top'
                                        }}
                                    >
                                        <ParamsTitle>{`${texts['view.background'].value}:`}</ParamsTitle>
                                        <ThemeSwitch
                                            style={{
                                                background: '#787878'
                                            }}
                                            checkedChildren="🌜"
                                            unCheckedChildren="🌞"
                                            onChange={(value: boolean) => {
                                                setThemeViewer(
                                                    value ? 'dark' : 'light'
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={24} sm={24} xs={24}>
                                <OrderStlViewSettings
                                    theme={themeFromQuery}
                                    hideButtons={false}
                                    texts={texts}
                                    header={texts['menu.settings'].value}
                                    modelColor={
                                        stlSettings
                                            ? stlSettings.model_color
                                            : '#e6bf8b'
                                    }
                                    modelAfterColor={shape.modelAfterColor}
                                    modelShineness={shape.modelShineness}
                                    modelAfterShineness={
                                        shape.modelAfterShineness
                                    }
                                    templateColor={
                                        stlSettings
                                            ? stlSettings.template_color
                                            : '#e6e1d6'
                                    }
                                    templateShineness={shape.templateShineness}
                                    minivintsColor={
                                        stlSettings
                                            ? stlSettings.minivints_color
                                            : '#009a63'
                                    }
                                    minivintsShineness={
                                        shape.minivintsShineness
                                    }
                                    toolColor={
                                        stlSettings
                                            ? stlSettings.tool_color
                                            : '#ffffff'
                                    }
                                    toolShineness={shape.toolShineness}
                                    trayColor={
                                        stlSettings
                                            ? stlSettings.tray_color
                                            : '#ffffff'
                                    }
                                    trayShineness={shape.trayShineness}
                                    trayOpacity={shape.trayOpacity}
                                    splintColor={
                                        stlSettings
                                            ? stlSettings.splint_color
                                                ? stlSettings.splint_color
                                                : '#aeccdc'
                                            : '#aeccdc'
                                    }
                                    splintShineness={shape.splintShineness}
                                    splintOpacity={shape.splintOpacity}
                                    maxillaryColor={
                                        stlSettings
                                            ? stlSettings.maxillary_color
                                                ? stlSettings.maxillary_color
                                                : '#e8a0a0'
                                            : '#e8a0a0'
                                    }
                                    maxillaryShineness={
                                        shape.maxillaryShineness
                                    }
                                    onChangeColor={onChangeColor}
                                    onDefault={onDefault}
                                    onChangeShineness={onChangeShineness}
                                    onChangeOpacity={onChangeOpacity}
                                    onSaveSettings={() => {
                                        dispatch(
                                            onSaveStlSettings(
                                                shape.modelColor,
                                                shape.modelAfterColor,
                                                shape.minivintsColor,
                                                shape.templateColor,
                                                shape.toolColor,
                                                shape.trayColor,
                                                shape.modelShineness,
                                                shape.modelAfterShineness,
                                                shape.minivintsShineness,
                                                shape.templateShineness,
                                                shape.toolShineness,
                                                shape.trayShineness,
                                                shape.trayOpacity,
                                                shape.splintColor,
                                                shape.splintShineness,
                                                shape.splintOpacity,
                                                shape.maxillaryColor,
                                                shape.maxillaryShineness
                                            )
                                        );
                                        message.success(
                                            texts['stl.settings.applied'].value
                                        );
                                    }}
                                />
                            </Col>
                        </RowStyled>
                        <Shape
                            // @ts-ignore
                            theme={themeViewer}
                            before={shape.before}
                            after={shape.after}
                            upper={shape.upper}
                            lower={shape.lower}
                            maxillaryUpper={stl10}
                            maxillaryUpperAfter={stl11}
                            maxillaryLower={stl12}
                            maxillaryLowerAfter={stl13}
                            maxillaryVisible={shape.maxillaryVisible}
                            maxillaryOpacity={shape.maxillaryOpacity}
                            maxillaryColor={shape.maxillaryColor}
                            maxillaryShineness={shape.maxillaryShineness}
                            model={stl1}
                            modelLower={stl8}
                            modelAfter={stl7}
                            modelLowerAfter={stl9}
                            modelVisible={shape.modelVisible}
                            modelColor={shape.modelColor}
                            modelShineness={shape.modelShineness}
                            modelAfterColor={shape.modelAfterColor}
                            modelAfterShineness={shape.modelAfterShineness}
                            extra={stl6}
                            extraVisible={shape.extraVisible}
                            extraColor={shape.extraColor}
                            extraShineness={shape.extraShineness}
                            minivints={stl2}
                            minivintsVisible={shape.minivintsVisible}
                            minivintsColor={shape.minivintsColor}
                            minivintsShineness={shape.minivintsShineness}
                            tool={stl3}
                            toolVisible={shape.toolVisible}
                            toolColor={shape.toolColor}
                            toolShineness={shape.toolShineness}
                            bracesUpper={stl18}
                            bracesLower={stl19}
                            bracesVisible={shape.bracesVisible}
                            bracesShineness={shape.bracesShineness}
                            bracesColor={shape.bracesColor}
                            template={stl4}
                            templateVisible={shape.templateVisible}
                            templateColor={shape.templateColor}
                            templateShineness={shape.templateShineness}
                            tray={stl5}
                            trayVisible={shape.trayVisible}
                            trayColor={shape.trayColor}
                            trayShineness={shape.trayShineness}
                            trayOpacity={shape.trayOpacity}
                            splint={stl20}
                            splintVisible={shape.splintVisible}
                            splintColor={shape.splintColor}
                            splintShineness={shape.splintShineness}
                            splintOpacity={shape.splintOpacity}
                            bordersVisible={shape.bordersVisible}
                            slices={slices}
                            sliceIndex={`${sliceIndex}`}
                            sceneView={sceneView}
                            showOverLayer={showOverLayer}
                            onCompletelyLoaded={() => {
                                setSlicesCompletelyLoaded(true);
                            }}
                        />
                        <SliceSlider
                            // value={sliceIndex}
                            disabled={!slicesCompletelyLoaded}
                            hidden={!isSlicesAvailable}
                            slices={slices}
                            showOverLayer={showOverLayer}
                            onChange={(sliderValue: any) => {
                                setSliceIndex(sliderValue);
                            }}
                            onShowOverLayer={(showLayerValue: any) => {
                                setShowOverLayer(showLayerValue);
                            }}
                        />
                    </Col>
                </RowStyled>
            );
        }

        content = (
            <>
                {/* <Head>
                    <title>{this.getTitle(order, texts)}</title>
                </Head> */}
                <Row gutter={16} style={{ margin: 0 }}>
                    <Col lg={24} md={24} style={{ padding: 0 }}>
                        <Layout
                            style={{
                                background:
                                    themeFromQuery === 'dark'
                                        ? '#222b45'
                                        : '#ffffff'
                            }}
                        >
                            <ContentStyled
                                style={{
                                    color:
                                        themeFromQuery === 'dark'
                                            ? '#ffffff'
                                            : '#222b45',
                                    background:
                                        themeFromQuery === 'dark'
                                            ? '#222b45'
                                            : '#ffffff'
                                }}
                            >
                                {stlViewer}
                                <Divider />
                            </ContentStyled>
                        </Layout>
                    </Col>
                </Row>
            </>
        );
    }
    return <>{content}</>;
};
