import React, { useState } from 'react';
import { Collapse, Slider } from 'antd';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { OrderStlViewSettingsToolbar } from './OrderStlViewSettingsToolbar';

const { Panel } = Collapse;
const StyledCollapse = styled(Collapse)`
    margin-top: 16px;
    border: none;
    background: ${(props) => (props.theme === 'dark' ? '#2E3A59' : 'none')};
    /* #2E3A59 */
    .ant-collapse-item {
        border: none;

        .ant-collapse-content {
            border: none;
            color: ${(props) =>
                props.theme === 'dark' ? '#fff' : '#000000a6'};
            > .ant-collapse-content-box {
                padding: 1px;
            }
            background: ${(props) =>
                props.theme === 'dark' ? '#2E3A59' : 'none'};
        }

        .ant-collapse-header {
            border: none;
            padding-bottom: 0px;
            padding-top: 0px;
            padding-left: 16px;
            color: ${(props) => (props.theme === 'dark' ? '#fff' : '#3C9FD9')};

            .ant-collapse-arrow {
                left: 0px;
            }
        }
    }

    .ant-slider-mark-text {
        color: ${(props) =>
            props.theme === 'dark' ? '#ffffff87' : '#000000a6'};

        &.ant-slider-mark-text-active {
            color: ${(props) =>
                props.theme === 'dark' ? '#ffffff' : '#000000a6'};
        }
    }
`;

const Color = styled.div`
    width: 36px;
    height: 14px;
    border-radius: 2px;
    background: ${(props) => props.color};
`;

const Swatch = styled.div`
    margin-top: 4px;
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
`;

const Popover = styled.div`
    position: absolute;
    z-index: 2;
`;

const Cover = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;

const ParamsTitle = styled.div`
    margin-top: 8px;
    font-weight: 500;
`;

const ParamsField = styled.div`
    margin: 0 8px;
    min-width: 140px;
`;

const Space = styled.div`
    display: flex;
`;

interface ViewSettings {
    header: string;
    modelColor: string;
    modelAfterColor: string;
    modelShineness: number;
    modelAfterShineness: number;
    templateColor: string;
    templateShineness: number;
    minivintsColor: string;
    minivintsShineness: number;
    toolColor: string;
    toolShineness: number;
    trayOpacity: number;
    trayColor: string;
    trayShineness: number;
    // splint
    splintColor: string;
    splintShineness: number;
    splintOpacity: number;
    // maxillary
    maxillaryColor: string;
    maxillaryShineness: number;
    // handlers
    onChangeColor: any;
    onChangeShineness: any;
    onChangeOpacity: any;
    onDefault: any;
    onSaveSettings: any;
    texts: any;
    hideButtons: boolean;
    theme?: any;
}

export const OrderStlViewSettings = (props: ViewSettings) => {
    const {
        header,
        modelColor,
        modelAfterColor,
        modelShineness,
        modelAfterShineness,
        templateColor,
        templateShineness,
        minivintsColor,
        minivintsShineness,
        toolColor,
        toolShineness,
        trayColor,
        trayShineness,
        trayOpacity,
        splintColor,
        splintShineness,
        splintOpacity,
        // maxillary
        maxillaryColor,
        maxillaryShineness,
        // handlers
        onChangeColor,
        onChangeShineness,
        onChangeOpacity,
        onDefault,
        onSaveSettings,
        texts,
        hideButtons,
        theme
    } = props;
    const [displayModelColorPicker, setDisplayModelColorPicker] = useState(
        false
    );
    const [
        displayModelAfterColorPicker,
        setDisplayModelAfterColorPicker
    ] = useState(false);
    const [displayToolColorPicker, setDisplayToolColorPicker] = useState(false);
    const [displayTrayColorPicker, setDisplayTrayColorPicker] = useState(false);
    const [displaySplintColorPicker, setDisplaySplintColorPicker] = useState(
        false
    );
    const [
        displayMaxillaryColorPicker,
        setDisplayMaxillaryColorPicker
    ] = useState(false);
    const [
        displayMinivintsColorPicker,
        setDisplayMinivintsColorPicker
    ] = useState(false);
    const [
        displayTemplateColorPicker,
        setDisplayTemplateColorPicker
    ] = useState(false);
    const [localModelColor, setLocalModelColor] = useState(modelColor);
    const [localModelAfterColor, setLocalModelAfterColor] = useState(
        modelAfterColor
    );
    const [localTemplateColor, setLocalTemplateColor] = useState(templateColor);
    const [localMinivintsColor, setLocalMinivintsColor] = useState(
        minivintsColor
    );
    const [localToolColor, setLocalToolColor] = useState(toolColor);
    const [localTrayColor, setLocalTrayColor] = useState(trayColor);
    const [localSplintColor, setLocalSplintColor] = useState(splintColor);
    const [localMaxillaryColor, setLocalMaxillaryColor] = useState(
        maxillaryColor
    );

    const handleClick = (what: string) => {
        if (what === 'model') {
            setDisplayModelColorPicker(!displayModelColorPicker);
        } else if (what === 'modelAfter') {
            setDisplayModelAfterColorPicker(!displayModelAfterColorPicker);
        } else if (what === 'template') {
            setDisplayTemplateColorPicker(!displayTemplateColorPicker);
        } else if (what === 'minivints') {
            setDisplayMinivintsColorPicker(!displayMinivintsColorPicker);
        } else if (what === 'tool') {
            setDisplayToolColorPicker(!displayTrayColorPicker);
        } else if (what === 'tray') {
            setDisplayTrayColorPicker(!displayTrayColorPicker);
        } else if (what === 'splint') {
            setDisplaySplintColorPicker(!displaySplintColorPicker);
        } else if (what === 'maxillary') {
            setDisplayMaxillaryColorPicker(!displayMaxillaryColorPicker);
        }
    };
    const handleClose = (what: string) => {
        if (what === 'model') {
            setDisplayModelColorPicker(false);
        } else if (what === 'modelAfter') {
            setDisplayModelAfterColorPicker(false);
        } else if (what === 'template') {
            setDisplayTemplateColorPicker(false);
        } else if (what === 'minivints') {
            setDisplayMinivintsColorPicker(false);
        } else if (what === 'tool') {
            setDisplayToolColorPicker(false);
        } else if (what === 'tray') {
            setDisplayTrayColorPicker(false);
        } else if (what === 'splint') {
            setDisplaySplintColorPicker(false);
        } else if (what === 'maxillary') {
            setDisplayMaxillaryColorPicker(false);
        }
    };
    const handleModelColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalModelColor(value);
        onChangeColor('model', value);
    };
    const handleModelAfterColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalModelAfterColor(value);
        onChangeColor('modelAfter', value);
    };
    const handleTemplateColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalTemplateColor(value);
        onChangeColor('template', value);
    };
    const handleMinivintsColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalMinivintsColor(value);
        onChangeColor('minivints', value);
    };
    const handleToolColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalToolColor(value);
        onChangeColor('tool', value);
    };
    const handleTrayColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalTrayColor(value);
        onChangeColor('tray', value);
    };
    const handleSplintColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalSplintColor(value);
        onChangeColor('splint', value);
    };
    const handleMaxillaryColorChange = (newColor: any) => {
        const value = newColor.hex ? newColor.hex : newColor;
        setLocalMaxillaryColor(value);
        onChangeColor('maxillary', value);
    };

    let toolbar;
    if (!hideButtons) {
        toolbar = (
            <OrderStlViewSettingsToolbar
                defaultText={texts.default.value}
                saveText={texts.save.value}
                onDefaultClick={() => {
                    setLocalModelColor('#E6BF8B');
                    setLocalModelAfterColor('#f6f5e8');
                    setLocalMinivintsColor('#009a63');
                    setLocalTemplateColor('#e6e1d6');
                    setLocalToolColor('#ffffff');
                    setLocalTrayColor('#ffffff');
                    setLocalSplintColor('#aeccdc');
                    setLocalMaxillaryColor('#e8a0a0');
                    onDefault(
                        '#E6BF8B',
                        '#f6f5e8',
                        '#009a63',
                        '#e6e1d6',
                        '#ffffff',
                        '#ffffff',
                        '#aeccdc',
                        '#e8a0a0',
                        15,
                        20,
                        100,
                        20,
                        55,
                        100,
                        70,
                        100,
                        70,
                        60
                    );
                }}
                onSaveClick={onSaveSettings}
            />
        );
    }
    return (
        <StyledCollapse theme={theme}>
            <Panel header={header} key="settings">
                {toolbar}
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.model.color'].value}
                            {` ${texts.before.value.toUpperCase()}:`}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('model')}>
                            <Color color={localModelColor} />
                        </Swatch>
                        {displayModelColorPicker ? (
                            <Popover>
                                <Cover onClick={() => handleClose('model')} />
                                <SketchPicker
                                    color={localModelColor}
                                    onChange={handleModelColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.model.shineness'].value}
                            {` ${texts.before.value.toUpperCase()}:`}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={modelShineness}
                            onChange={(value) =>
                                onChangeShineness('model', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.model.color'].value}
                            {` ${texts.after.value.toUpperCase()}:`}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('modelAfter')}>
                            <Color color={localModelAfterColor} />
                        </Swatch>
                        {displayModelAfterColorPicker ? (
                            <Popover>
                                <Cover
                                    onClick={() => handleClose('modelAfter')}
                                />
                                <SketchPicker
                                    color={localModelAfterColor}
                                    onChange={handleModelAfterColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.model.shineness'].value}
                            {` ${texts.before.value.toUpperCase()}:`}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={modelAfterShineness}
                            onChange={(value) =>
                                onChangeShineness('modelAfter', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.template.color'].value}
                            {`: `}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('template')}>
                            <Color color={localTemplateColor} />
                        </Swatch>
                        {displayTemplateColorPicker ? (
                            <Popover>
                                <Cover
                                    onClick={() => handleClose('template')}
                                />
                                <SketchPicker
                                    color={localTemplateColor}
                                    onChange={handleTemplateColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.template.shineness'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={templateShineness}
                            onChange={(value) =>
                                onChangeShineness('template', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.minivints.color'].value}
                            {`: `}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('minivints')}>
                            <Color color={localMinivintsColor} />
                        </Swatch>
                        {displayMinivintsColorPicker ? (
                            <Popover>
                                <Cover
                                    onClick={() => handleClose('minivints')}
                                />
                                <SketchPicker
                                    color={localMinivintsColor}
                                    onChange={handleMinivintsColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.minivints.shineness'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={minivintsShineness}
                            onChange={(value) =>
                                onChangeShineness('minivints', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.tool.color'].value}
                            {`: `}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('tool')}>
                            <Color color={localToolColor} />
                        </Swatch>
                        {displayToolColorPicker ? (
                            <Popover>
                                <Cover onClick={() => handleClose('tool')} />
                                <SketchPicker
                                    color={localToolColor}
                                    onChange={handleToolColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.tool.shineness'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={toolShineness}
                            onChange={(value) =>
                                onChangeShineness('tool', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.tray.color'].value}
                            {`: `}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('tray')}>
                            <Color color={localTrayColor} />
                        </Swatch>
                        {displayTrayColorPicker ? (
                            <Popover>
                                <Cover onClick={() => handleClose('tray')} />
                                <SketchPicker
                                    color={localTrayColor}
                                    onChange={handleTrayColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.tray.shineness'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={trayShineness}
                            onChange={(value) =>
                                onChangeShineness('tray', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField />
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.tray.opacity'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={trayOpacity}
                            onChange={(value) => onChangeOpacity('tray', value)}
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.maxillary.color'].value}
                            {`: `}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('maxillary')}>
                            <Color color={localMaxillaryColor} />
                        </Swatch>
                        {displayMaxillaryColorPicker ? (
                            <Popover>
                                <Cover
                                    onClick={() => handleClose('maxillary')}
                                />
                                <SketchPicker
                                    color={localMaxillaryColor}
                                    onChange={handleMaxillaryColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.maxillary.shineness'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={maxillaryShineness}
                            onChange={(value) =>
                                onChangeShineness('maxillary', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.splint.color'].value}
                            {`: `}
                        </ParamsTitle>
                        <Swatch onClick={() => handleClick('splint')}>
                            <Color color={localSplintColor} />
                        </Swatch>
                        {displaySplintColorPicker ? (
                            <Popover>
                                <Cover onClick={() => handleClose('splint')} />
                                <SketchPicker
                                    color={localSplintColor}
                                    onChange={handleSplintColorChange}
                                />
                            </Popover>
                        ) : null}
                    </ParamsField>
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.splint.shineness'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={splintShineness}
                            onChange={(value) =>
                                onChangeShineness('splint', value)
                            }
                        />
                    </ParamsField>
                </Space>
                <Space>
                    <ParamsField />
                    <ParamsField>
                        <ParamsTitle>
                            {texts['stl.splint.opacity'].value}
                            {`: `}
                        </ParamsTitle>
                        <Slider
                            marks={{ 0: '0', 100: '100' }}
                            step={5}
                            value={splintOpacity}
                            onChange={(value) =>
                                onChangeOpacity('splint', value)
                            }
                        />
                    </ParamsField>
                </Space>
            </Panel>
        </StyledCollapse>
    );
};
