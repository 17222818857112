import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import qs from 'qs';
import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Modal,
    Icon,
    Layout,
    Menu,
    Radio,
    Row,
    Spin,
    Divider,
    message,
    Tabs,
    Slider,
    Switch
} from 'antd';
import styled from 'styled-components';
import { orderBy, find, indexOf } from 'lodash';
// import Head from 'next/head';
import copy from 'copy-to-clipboard';
import {
    selectOrder,
    openNewOrder,
    deleteOrder,
    selectOrderBase,
    onSaveStlSettings
} from '../../redux/actions';
import CreateOrderModalForm from '../../components/NewOrder/CreateOrderModalForm';
import NewOrderModalForm from '../../components/NewOrder/NewOrderModalForm';
import { OrderComments } from '../../components/Order/OrderComments';
import { OrderInternalComments } from '../../components/Order/OrderInternalComments';
import OrderCardParams from '../../components/Order/OrderCardParams';
import OrderImageGallery from '../../components/Order/OrderImageGallery';
import Shape from '../../components/Order/OrderStlFiber';
import { OrderStlViewSettings } from '../../components/Order/OrderStlViewSettings';
import { GATEWAY_HOST, UI_HOST } from '../../utils/properties';
import { UpperJawIcon } from '../../components/Icon/UpperJawIcon';
import { LowerJawIcon } from '../../components/Icon/LowerJawIcon';
import { OrderGifCard } from '../../components/Order/OrderGifCard';
import { SliceSlider } from '../../components/Order/SliceSlider';
import { isEmpty } from '../../utils/common';
import { LabelsPrinter } from '../../components/Label/LabelsPrinter';
import { AlignersInfoModal } from '../../components/NewOrder/AlignersInfoModal';
import { AlignersInfo } from '../../components/Aligners/AlignersInfo';

const { Content } = Layout;
const { TabPane } = Tabs;
const { confirm } = Modal;

const TabsContainer = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;
`;

const TabsBody = styled(TabPane)`
    padding: 4px 24px 24px;
`;

const ContentStyled = styled(Content)`
    padding: 16px;
    background: #fff;
    max-width: 900px;
    border-radius: 4px;
`;

const ContentSpinStyled = styled(Content)`
    padding: 16px;
    margin: 128px auto;
    max-width: 900px;
    border-radius: 4px;
`;

const RowStyled = styled(Row)`
    /* margin-bottom: 36px; */
`;

const ParamsTitle = styled.div`
    margin-top: 8px;
    font-weight: 500;
`;

const ContentLayout = styled(Layout)`
    background: #fff;
`;

const ButtonAction = styled(Button)`
    margin-right: 8px;
`;

const ActionPanel = styled.div`
    margin-right: 8px;
`;

const ModelUpperPanel = styled.div`
    display: inline-block;
    vertical-align: top;

    @media (max-width: 1108px) {
        display: none;
    }
`;
const ModelBottomPanel = styled.div`
    display: none;

    @media (max-width: 1108px) {
        display: block;
    }
`;

const ThemeSwitch = styled(Switch)`
    .ant-switch-inner {
        font-size: 16px;
    }
`;

const ModelLowerButon = styled(Button)`
    height: 64px !important;
    display: inline-block !important;
    margin-left: 28px !important;
    vertical-align: top !important;

    @media (max-width: 1108px) {
        display: none !important;
    }
`;

const MaxillaryUpperPanel = styled.div`
    display: inline-block;
    vertical-align: top;
    @media (max-width: 1108px) {
        display: none !important;
    }
`;
const MaxillaryBottomPanel = styled.div`
    display: none;

    @media (max-width: 1108px) {
        display: block;
    }
`;

export const OrderPage = () => {
    const queryClient = useQueryClient();
    const location = useLocation();
    const params = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const {
        id: idFromQuery,
        revision: revisionFromQuery,
        notification: notificationFromQuery,
        tab: tabFromQuery
    } = params;

    let activeTab = '1';
    if (tabFromQuery === 'messages') {
        activeTab = '2';
    } else if (tabFromQuery === 'comments') {
        activeTab = '3';
    }

    const dispatch = useDispatch();
    const order = useSelector((state: any) => state.OrderReducer.selectedOrder);
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const stlSettings = useSelector(
        (state: any) => state.ProfileReducer.profile.stl
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const [revision, setRevision] = useState(revisionFromQuery);
    const [theme, setTheme] = useState('light');
    const [slicesCompletelyLoaded, setSlicesCompletelyLoaded] = useState(false);
    const [id, setId] = useState(idFromQuery);
    // const [sceneView, setSceneView] = useState([
    //     -Math.PI / 2,
    //     Math.PI,
    //     Math.PI
    // ]);
    const [sceneView, setSceneView] = useState([0, 0, 0]);
    const [sliceIndex, setSliceIndex] = useState(0);
    const [showOverLayer, setShowOverLayer] = useState(false);
    const [alignersInfoVisible, setAlignersInfoVisible] = useState(false);

    const [shape, setShape] = useState({
        before: true,
        after: false,
        upper: true,
        lower: true,
        maxillaryUpper: undefined,
        maxillaryLower: undefined,
        maxillaryUpperAfter: undefined,
        maxillaryLowerAfter: undefined,
        maxillaryVisible: true,
        maxillaryColor: stlSettings
            ? stlSettings.maxillary_color
                ? stlSettings.maxillary_color
                : '#e8a0a0'
            : '#e8a0a0',
        maxillaryShineness: stlSettings
            ? stlSettings.maxillary_shineness
                ? stlSettings.maxillary_shineness
                : 60
            : 60,
        maxillaryOpacity: stlSettings
            ? stlSettings.maxillary_opacity
                ? stlSettings.maxillary_opacity
                : 100
            : 100,
        // model
        model: undefined,
        modelVisible: true,
        modelColor: stlSettings ? stlSettings.model_color : '#e6bf8b',
        modelAfterColor: stlSettings
            ? stlSettings.model_after_color
                ? stlSettings.model_after_color
                : '#f6f5e8'
            : '#f6f5e8',
        modelShineness: stlSettings ? stlSettings.model_shineness : 15,
        modelAfterShineness: stlSettings
            ? stlSettings.model_after_shineness
                ? stlSettings.model_after_shineness
                : 20
            : 20,
        extra: undefined,
        extraVisible: true,
        extraColor: stlSettings
            ? stlSettings.extra_color
                ? stlSettings.extra_color
                : '#696d6c'
            : '#696d6c',
        extraShineness: stlSettings
            ? stlSettings.model_shineness
                ? stlSettings.model_shineness
                : 100
            : 100,
        minivints: undefined,
        minivintsVisible: true,
        minivintsColor: stlSettings ? stlSettings.minivints_color : '#009a63',
        minivintsShineness: stlSettings ? stlSettings.minivints_shineness : 100,
        tool: undefined,
        toolVisible: true,
        toolColor: stlSettings ? stlSettings.tool_color : '#ffffff',
        toolShineness: stlSettings ? stlSettings.tool_shineness : 55,
        // splint
        splint: undefined,
        splintVisible: true,
        splintColor: stlSettings
            ? stlSettings.splint_color
                ? stlSettings.splint_color
                : '#aeccdc'
            : '#aeccdc',
        splintShineness: stlSettings ? stlSettings.splint_shineness : 100,
        splintOpacity: stlSettings ? stlSettings.splint_opacity : 70,
        // braces
        bracesUpper: undefined,
        bracesLower: undefined,
        bracesVisible: false,
        bracesColor: stlSettings ? stlSettings.braces_color : '#ffffff',
        bracesShineness: stlSettings ? stlSettings.braces_shineness : 50,
        // template
        template: undefined,
        templateVisible: false,
        templateColor: stlSettings ? stlSettings.template_color : '#e6e1d6',
        templateShineness: stlSettings ? stlSettings.template_shineness : 20,
        tray: undefined,
        trayVisible: true,
        trayColor: stlSettings ? stlSettings.tray_color : '#ffffff',
        trayShineness: stlSettings ? stlSettings.tray_shineness : 100,
        trayOpacity: stlSettings ? stlSettings.tray_opacity : 70,
        bordersVisible: false,
        mode: 'tool'
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const newParams = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setId(newParams.id);
        setRevision(newParams.revision);
    }, [location]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (notificationFromQuery && jwt) {
            fetch(`${GATEWAY_HOST}/notifications/${notificationFromQuery}`, {
                method: 'PUT',
                body: JSON.stringify({ read: true }),
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                queryClient.refetchQueries('notificationsUnreadCount');
            });
        }
    }, [notificationFromQuery, jwt]);

    const getUsername = (doctor: any) => {
        // order.doctor.last_name, order.doctor.first_name, order.doctor.middle_name
        let username = doctor.last_name;
        if (!isEmpty(doctor.first_name)) {
            username += ` ${doctor.first_name[0]}.`;
        }

        if (!isEmpty(doctor.middle_name)) {
            username += ` ${doctor.middle_name[0]}.`;
        }
        return username;
    };

    const getStageByState = (currentOrder: any) => {
        let stage = null;
        if (
            indexOf(
                ['NEW', 'ON_APPROVAL', 'CORRECTION'],
                currentOrder.status_minivints
            ) >= 0
        ) {
            stage = 'stage1';
        } else if (
            indexOf(
                ['NEW', 'ON_APPROVAL', 'CORRECTION'],
                currentOrder.status_template
            ) >= 0
        ) {
            stage = 'stage2';
        } else if (
            indexOf(
                ['NEW', 'ON_APPROVAL', 'CORRECTION'],
                currentOrder.status_equipment
            ) >= 0
        ) {
            stage = 'stage3';
        }
        return stage;
    };

    const onCopy = (orderId: any, revision: any, texts: any, order: any) => {
        const doctor = getUsername(order.doctor);
        let msg = `${texts['order.doctor'].value}: ${doctor}; ${texts['order.patient'].value}: ${order.patient};`;
        const stage = getStageByState(order);
        if (stage) {
            msg = `${msg} ${texts['order.stage'].value}: ${
                texts[`order.stage.${stage}`].value
            };`;
        }
        copy(`
            ${msg} ${texts.link.value}: ${UI_HOST}/shared?id=${orderId}&revision=${revision}
        `);
        message.success(texts['share.success'].value);
    };

    // const getTitle = (order: any, texts: any) => {
    //     const doctor = getUsername(order.doctor);
    //     const msg = `${texts['order.doctor'].value}: ${doctor}; ${texts['order.patient'].value}: ${order.patient}`;
    //     return msg;
    // };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dispatch(selectOrder(id, revision));
    }, [id, revision, dispatch]);

    const onChangeModelUpper = () => {
        const newShape = { ...shape };
        newShape.upper = !newShape.upper;
        setShape(newShape);
    };

    const onChangeModelLower = () => {
        const newShape = { ...shape };
        newShape.lower = !newShape.lower;
        setShape(newShape);
    };

    const onChangeMinivints = (e: any) => {
        const newShape = { ...shape };
        newShape.minivintsVisible = e.target.checked;
        if (newShape.minivintsVisible) {
            newShape.after = false;
            newShape.before = true;
        }
        setShape(newShape);
    };

    const onChangeBefore = (e: any) => {
        const newShape = { ...shape };
        newShape.before = e.target.checked;
        setShape(newShape);
    };
    const onChangeAfter = (e: any) => {
        const newShape = { ...shape };
        newShape.after = e.target.checked;
        if (
            (!newShape.after && order.status_braces !== 'NONE') ||
            order.status_aligners !== 'NONE'
        ) {
            newShape.mode = 'none';
            // newShape.after = false;
            // newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        }
        setShape(newShape);
    };

    const onChangeExtra = (e: any) => {
        const newShape = { ...shape };
        newShape.extraVisible = e.target.checked;
        setShape(newShape);
    };

    const onChangeMode = (e: any) => {
        const newShape = { ...shape };
        const currentOrder = order;
        if (e.target.value === 'tool') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = true;
            newShape.trayVisible = true;
            newShape.splintVisible = false;
        } else if (e.target.value === 'splint') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = true;
        } else if (e.target.value === 'template') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = true;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        } else if (e.target.value === 'braces') {
            newShape.mode = e.target.value;
            newShape.after = true;
            newShape.before = false;
            newShape.bracesVisible = true;
            newShape.templateVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        } else {
            newShape.mode = e.target.value;
            // newShape.after = false;
            // newShape.before = true;
            if (
                currentOrder.status_braces !== 'NONE' ||
                currentOrder.status_aligners !== 'NONE'
            ) {
                newShape.after = true;
                newShape.before = false;
            }

            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        }
        setShape(newShape);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (order) {
            const orderedVersions = orderBy(
                order.orderversions,
                ['design_date'],
                ['desc']
            );
            const orderVersion =
                revision === 'latest'
                    ? orderedVersions[0]
                    : find(orderedVersions, (o) => `${o.id}` === revision);
            // equipment
            const stl3 = isEmpty(orderVersion.stl_3)
                ? ''
                : orderVersion.stl_3.rawUrl;
            // equipment
            const stl4 = isEmpty(orderVersion.stl_4)
                ? ''
                : orderVersion.stl_4.rawUrl;
            // equipment
            const stl18 = isEmpty(orderVersion.stl_18)
                ? ''
                : orderVersion.stl_18.rawUrl;
            // equipment
            const stl19 = isEmpty(orderVersion.stl_19)
                ? ''
                : orderVersion.stl_19.rawUrl;
            // equipment
            const stl20 = isEmpty(orderVersion.stl_20)
                ? ''
                : orderVersion.stl_20.rawUrl;
            let result = 'none';
            if (stl3 !== '') {
                result = 'tool';
            } else if (stl4 !== '') {
                result = 'template';
            } else if (stl18 !== '' || stl19 !== '') {
                result = 'braces';
            } else if (stl20 !== '') {
                result = 'splint';
            }
            onChangeMode({ target: { value: result } });
        }
    }, [order]);

    const onEditClick = (e: any, record: any, orderVersion: any) => {
        const orderedVersions = orderBy(
            order.orderversions,
            ['design_date'],
            ['desc']
        );
        const latestRevisionInVersion = find(
            orderedVersions,
            (o) => o.version === orderVersion.version
        );
        dispatch(selectOrder(record.id, latestRevisionInVersion.id));
        dispatch(openNewOrder(record.id));
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: texts['order.delete.confirmation'].value,
            content: texts['order.delete.confirmation.details'].value,
            okText: texts.yes.value,
            okType: 'danger',
            cancelText: texts.no.value,
            onOk() {
                dispatch(deleteOrder(record.id));
                window.location.href = '/';
            }
        });
    };

    const onDeleteClick = (e: any, record: any) => {
        showDeleteConfirm(record);
    };

    const onNewVersionClick = (e: any, record: any) => {
        dispatch(selectOrderBase(record.id, 'latest'));
        dispatch(openNewOrder(record.id, true));
    };

    const onChangeColor = (what: string, color: string) => {
        const newShape = { ...shape };
        if (what === 'model') {
            newShape.modelColor = color;
        } else if (what === 'modelAfter') {
            newShape.modelAfterColor = color;
        } else if (what === 'template') {
            newShape.templateColor = color;
        } else if (what === 'minivints') {
            newShape.minivintsColor = color;
        } else if (what === 'tray') {
            newShape.trayColor = color;
        } else if (what === 'tool') {
            newShape.toolColor = color;
        } else if (what === 'splint') {
            newShape.splintColor = color;
        } else if (what === 'maxillary') {
            newShape.maxillaryColor = color;
        }
        setShape(newShape);
    };

    const onDefault = (
        newModelColor: string,
        newModelAfterColor: string,
        newMinivintsColor: string,
        newTemplateColor: string,
        newToolColor: string,
        newTrayColor: string,
        newSplintColor: string,
        newMaxillaryColor: string,
        newModelShineness: number,
        newModelAfterShineness: number,
        newMinivintsShineness: number,
        newTemplateShineness: number,
        newToolShineness: number,
        newTrayShineness: number,
        newTrayOpacity: number,
        newSplintShineness: number,
        newSplintOpacity: number,
        newMaxillaryShineness: number
    ) => {
        const newShape = { ...shape };
        newShape.modelColor = newModelColor;
        newShape.modelAfterColor = newModelAfterColor;
        newShape.modelShineness = newModelShineness;
        newShape.modelAfterShineness = newModelAfterShineness;
        newShape.minivintsColor = newMinivintsColor;
        newShape.minivintsShineness = newMinivintsShineness;
        newShape.templateColor = newTemplateColor;
        newShape.templateShineness = newTemplateShineness;
        newShape.toolColor = newToolColor;
        newShape.toolShineness = newToolShineness;
        newShape.trayColor = newTrayColor;
        newShape.trayShineness = newTrayShineness;
        newShape.trayOpacity = newTrayOpacity;
        // Splint
        newShape.splintColor = newSplintColor;
        newShape.splintShineness = newSplintShineness;
        newShape.splintOpacity = newSplintOpacity;
        // Maxillary
        newShape.maxillaryColor = newMaxillaryColor;
        newShape.maxillaryShineness = newMaxillaryShineness;
        setShape(newShape);
    };

    const onChangeShineness = (what: string, shineness: number) => {
        const newShape = { ...shape };
        if (what === 'model') {
            newShape.modelShineness = shineness;
        } else if (what === 'modelAfter') {
            newShape.modelAfterShineness = shineness;
        } else if (what === 'template') {
            newShape.templateShineness = shineness;
        } else if (what === 'minivints') {
            newShape.minivintsShineness = shineness;
        } else if (what === 'tray') {
            newShape.trayShineness = shineness;
        } else if (what === 'tool') {
            newShape.toolShineness = shineness;
        } else if (what === 'splint') {
            newShape.splintShineness = shineness;
        } else if (what === 'maxillary') {
            newShape.maxillaryShineness = shineness;
        }
        setShape(newShape);
    };

    const onChangeOpacity = (what: string, opacity: number) => {
        const newShape = { ...shape };
        if (what === 'model') {
            // newShape.modelOpacity = opacity;
        } else if (what === 'template') {
            // newShape.templateOpacity = opacity;
        } else if (what === 'minivints') {
            // newShape.minivintsOpacity = opacity;
        } else if (what === 'tray') {
            newShape.trayOpacity = opacity;
        } else if (what === 'tool') {
            // newShape.toolOpacity = opacity;
        } else if (what === 'splint') {
            newShape.splintOpacity = opacity;
        } else if (what === 'maxillary') {
            newShape.maxillaryOpacity = opacity;
        }
        setShape(newShape);
    };

    let content = (
        <ContentLayout>
            <ContentSpinStyled>
                <Spin
                    size="large"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            </ContentSpinStyled>
        </ContentLayout>
    );
    if (order) {
        const orderedVersions = orderBy(
            order.orderversions,
            ['design_date'],
            ['desc']
        );
        const orderVersion =
            revision === 'latest'
                ? orderedVersions[0]
                : find(orderedVersions, (o) => `${o.id}` === revision);
        // template video
        const video = isEmpty(orderVersion.video)
            ? ''
            : orderVersion.video.rawUrl;
        // equipment gif
        const gif = isEmpty(orderVersion.gif) ? '' : orderVersion.gif.rawUrl;
        // equipment gif 2d
        const gif2d = isEmpty(orderVersion.gif2d)
            ? ''
            : orderVersion.gif2d.rawUrl;
        // equipment gif splint
        // eslint-disable-next-line
        const gif_splint = isEmpty(orderVersion.gif_splint)
            ? ''
            : orderVersion.gif_splint.rawUrl;
        const zip = isEmpty(orderVersion.zip) ? '' : orderVersion.zip.rawUrl;
        const { slices } = orderVersion;
        const isSlicesAvailable = !isEmpty(slices);
        // model
        const stl1 = isEmpty(orderVersion.stl_1)
            ? ''
            : orderVersion.stl_1.rawUrl;
        // minivints
        const stl2 = isEmpty(orderVersion.stl_2)
            ? ''
            : orderVersion.stl_2.rawUrl;
        // equipment
        const stl3 = isEmpty(orderVersion.stl_3)
            ? ''
            : orderVersion.stl_3.rawUrl;
        // template
        const stl4 = isEmpty(orderVersion.stl_4)
            ? ''
            : orderVersion.stl_4.rawUrl;
        // tray
        const stl5 = isEmpty(orderVersion.stl_5)
            ? ''
            : orderVersion.stl_5.rawUrl;
        // addition
        const stl6 = isEmpty(orderVersion.stl_6)
            ? ''
            : orderVersion.stl_6.rawUrl;
        // upper jaw model after
        const stl7 = isEmpty(orderVersion.stl_7)
            ? ''
            : orderVersion.stl_7.rawUrl;
        // lower jaw model before
        const stl8 = isEmpty(orderVersion.stl_8)
            ? ''
            : orderVersion.stl_8.rawUrl;
        // lower jaw model after
        const stl9 = isEmpty(orderVersion.stl_9)
            ? ''
            : orderVersion.stl_9.rawUrl;
        // upper maxillary model before
        const stl10 = isEmpty(orderVersion.stl_10)
            ? ''
            : orderVersion.stl_10.rawUrl;
        // upper maxillary model after
        const stl11 = isEmpty(orderVersion.stl_11)
            ? ''
            : orderVersion.stl_11.rawUrl;
        // lower maxillary model before
        const stl12 = isEmpty(orderVersion.stl_12)
            ? ''
            : orderVersion.stl_12.rawUrl;
        // lower maxillary model after
        const stl13 = isEmpty(orderVersion.stl_13)
            ? ''
            : orderVersion.stl_13.rawUrl;
        // upper jaw braces
        const stl18 = isEmpty(orderVersion.stl_18)
            ? ''
            : orderVersion.stl_18.rawUrl;
        // lower jaw braces
        const stl19 = isEmpty(orderVersion.stl_19)
            ? ''
            : orderVersion.stl_19.rawUrl;
        // splint
        const stl20 = isEmpty(orderVersion.stl_20)
            ? ''
            : orderVersion.stl_20.rawUrl;
        const img1 = isEmpty(orderVersion.image_1)
            ? ''
            : orderVersion.image_1.url;
        const img2 = isEmpty(orderVersion.image_2)
            ? ''
            : orderVersion.image_2.url;
        const img3 = isEmpty(orderVersion.image_3)
            ? ''
            : orderVersion.image_3.url;
        const img4 = isEmpty(orderVersion.image_4)
            ? ''
            : orderVersion.image_4.url;
        const img5 = isEmpty(orderVersion.image_5)
            ? ''
            : orderVersion.image_5.url;

        // minivints
        const minivintsImage1 = isEmpty(orderVersion.minivints_image_1)
            ? ''
            : orderVersion.minivints_image_1.url;
        const minivintsImage2 = isEmpty(orderVersion.minivints_image_2)
            ? ''
            : orderVersion.minivints_image_2.url;
        const minivintsImage3 = isEmpty(orderVersion.minivints_image_3)
            ? ''
            : orderVersion.minivints_image_3.url;
        const minivintsImage4 = isEmpty(orderVersion.minivints_image_4)
            ? ''
            : orderVersion.minivints_image_4.url;
        const minivintsImage5 = isEmpty(orderVersion.minivints_image_5)
            ? ''
            : orderVersion.minivints_image_5.url;
        const minivintsImage6 = isEmpty(orderVersion.minivints_image_6)
            ? ''
            : orderVersion.minivints_image_6.url;
        const minivintsImage7 = isEmpty(orderVersion.minivints_image_7)
            ? ''
            : orderVersion.minivints_image_7.url;
        const minivintsImage8 = isEmpty(orderVersion.minivints_image_8)
            ? ''
            : orderVersion.minivints_image_8.url;
        const minivintsImage9 = isEmpty(orderVersion.minivints_image_9)
            ? ''
            : orderVersion.minivints_image_9.url;
        const minivintsImage10 = isEmpty(orderVersion.minivints_image_10)
            ? ''
            : orderVersion.minivints_image_10.url;

        // template
        const templateImage1 = isEmpty(orderVersion.template_image_1)
            ? ''
            : orderVersion.template_image_1.url;
        const templateImage2 = isEmpty(orderVersion.template_image_2)
            ? ''
            : orderVersion.template_image_2.url;
        const templateImage3 = isEmpty(orderVersion.template_image_3)
            ? ''
            : orderVersion.template_image_3.url;
        const templateImage4 = isEmpty(orderVersion.template_image_4)
            ? ''
            : orderVersion.template_image_4.url;
        const templateImage5 = isEmpty(orderVersion.template_image_5)
            ? ''
            : orderVersion.template_image_5.url;
        const templateImage6 = isEmpty(orderVersion.template_image_6)
            ? ''
            : orderVersion.template_image_6.url;
        const templateImage7 = isEmpty(orderVersion.template_image_7)
            ? ''
            : orderVersion.template_image_7.url;
        const templateImage8 = isEmpty(orderVersion.template_image_8)
            ? ''
            : orderVersion.template_image_8.url;
        const templateImage9 = isEmpty(orderVersion.template_image_9)
            ? ''
            : orderVersion.template_image_9.url;
        const templateImage10 = isEmpty(orderVersion.template_image_10)
            ? ''
            : orderVersion.template_image_10.url;

        // tool
        const toolImage1 = isEmpty(orderVersion.tool_image_1)
            ? ''
            : orderVersion.tool_image_1.url;
        const toolImage2 = isEmpty(orderVersion.tool_image_2)
            ? ''
            : orderVersion.tool_image_2.url;
        const toolImage3 = isEmpty(orderVersion.tool_image_3)
            ? ''
            : orderVersion.tool_image_3.url;
        const toolImage4 = isEmpty(orderVersion.tool_image_4)
            ? ''
            : orderVersion.tool_image_4.url;
        const toolImage5 = isEmpty(orderVersion.tool_image_5)
            ? ''
            : orderVersion.tool_image_5.url;
        const toolImage6 = isEmpty(orderVersion.tool_image_6)
            ? ''
            : orderVersion.tool_image_6.url;
        const toolImage7 = isEmpty(orderVersion.tool_image_7)
            ? ''
            : orderVersion.tool_image_7.url;
        const toolImage8 = isEmpty(orderVersion.tool_image_8)
            ? ''
            : orderVersion.tool_image_8.url;
        const toolImage9 = isEmpty(orderVersion.tool_image_9)
            ? ''
            : orderVersion.tool_image_9.url;
        const toolImage10 = isEmpty(orderVersion.tool_image_10)
            ? ''
            : orderVersion.tool_image_10.url;

        // tool 2d
        const tool2dImage1 = isEmpty(orderVersion.tool_2d_image_1)
            ? ''
            : orderVersion.tool_2d_image_1.url;
        const tool2dImage2 = isEmpty(orderVersion.tool_2d_image_2)
            ? ''
            : orderVersion.tool_2d_image_2.url;
        const tool2dImage3 = isEmpty(orderVersion.tool_2d_image_3)
            ? ''
            : orderVersion.tool_2d_image_3.url;
        const tool2dImage4 = isEmpty(orderVersion.tool_2d_image_4)
            ? ''
            : orderVersion.tool_2d_image_4.url;
        const tool2dImage5 = isEmpty(orderVersion.tool_2d_image_5)
            ? ''
            : orderVersion.tool_2d_image_5.url;
        const tool2dImage6 = isEmpty(orderVersion.tool_2d_image_6)
            ? ''
            : orderVersion.tool_2d_image_6.url;
        const tool2dImage7 = isEmpty(orderVersion.tool_2d_image_7)
            ? ''
            : orderVersion.tool_2d_image_7.url;
        const tool2dImage8 = isEmpty(orderVersion.tool_2d_image_8)
            ? ''
            : orderVersion.tool_2d_image_8.url;
        const tool2dImage9 = isEmpty(orderVersion.tool_2d_image_9)
            ? ''
            : orderVersion.tool_2d_image_9.url;
        const tool2dImage10 = isEmpty(orderVersion.tool_2d_image_10)
            ? ''
            : orderVersion.tool_2d_image_10.url;

        // virtual setup
        const vsImage1 = isEmpty(orderVersion.vs_image_1)
            ? ''
            : orderVersion.vs_image_1.url;
        const vsImage2 = isEmpty(orderVersion.vs_image_2)
            ? ''
            : orderVersion.vs_image_2.url;
        const vsImage3 = isEmpty(orderVersion.vs_image_3)
            ? ''
            : orderVersion.vs_image_3.url;
        const vsImage4 = isEmpty(orderVersion.vs_image_4)
            ? ''
            : orderVersion.vs_image_4.url;
        const vsImage5 = isEmpty(orderVersion.vs_image_5)
            ? ''
            : orderVersion.vs_image_5.url;
        const vsImage6 = isEmpty(orderVersion.vs_image_6)
            ? ''
            : orderVersion.vs_image_6.url;
        const vsImage7 = isEmpty(orderVersion.vs_image_7)
            ? ''
            : orderVersion.vs_image_7.url;
        const vsImage8 = isEmpty(orderVersion.vs_image_8)
            ? ''
            : orderVersion.vs_image_8.url;
        const vsImage9 = isEmpty(orderVersion.vs_image_9)
            ? ''
            : orderVersion.vs_image_9.url;
        const vsImage10 = isEmpty(orderVersion.vs_image_10)
            ? ''
            : orderVersion.vs_image_10.url;
        const vsImage11 = isEmpty(orderVersion.vs_image_11)
            ? ''
            : orderVersion.vs_image_11.url;
        const vsImage12 = isEmpty(orderVersion.vs_image_12)
            ? ''
            : orderVersion.vs_image_12.url;
        const vsImage13 = isEmpty(orderVersion.vs_image_13)
            ? ''
            : orderVersion.vs_image_13.url;
        const vsImage14 = isEmpty(orderVersion.vs_image_14)
            ? ''
            : orderVersion.vs_image_14.url;
        const vsImage15 = isEmpty(orderVersion.vs_image_15)
            ? ''
            : orderVersion.vs_image_15.url;

        // splint
        const splintImage1 = isEmpty(orderVersion.splint_image_1)
            ? ''
            : orderVersion.splint_image_1.url;
        const splintImage2 = isEmpty(orderVersion.splint_image_2)
            ? ''
            : orderVersion.splint_image_2.url;
        const splintImage3 = isEmpty(orderVersion.splint_image_3)
            ? ''
            : orderVersion.splint_image_3.url;
        const splintImage4 = isEmpty(orderVersion.splint_image_4)
            ? ''
            : orderVersion.splint_image_4.url;
        const splintImage5 = isEmpty(orderVersion.splint_image_5)
            ? ''
            : orderVersion.splint_image_5.url;
        const splintImage6 = isEmpty(orderVersion.splint_image_6)
            ? ''
            : orderVersion.splint_image_6.url;
        const splintImage7 = isEmpty(orderVersion.splint_image_7)
            ? ''
            : orderVersion.splint_image_7.url;
        const splintImage8 = isEmpty(orderVersion.splint_image_8)
            ? ''
            : orderVersion.splint_image_8.url;
        const splintImage9 = isEmpty(orderVersion.splint_image_9)
            ? ''
            : orderVersion.splint_image_9.url;
        const splintImage10 = isEmpty(orderVersion.splint_image_10)
            ? ''
            : orderVersion.splint_image_10.url;

        const minivintsImages = [
            {
                original: minivintsImage1,
                thumbnail: minivintsImage1,
                title: orderVersion.minivints_label_1,
                index: 0
            },
            {
                original: minivintsImage2,
                thumbnail: minivintsImage2,
                title: orderVersion.minivints_label_2,
                index: 1
            },
            {
                original: minivintsImage3,
                thumbnail: minivintsImage3,
                title: orderVersion.minivints_label_3,
                index: 2
            },
            {
                original: minivintsImage4,
                thumbnail: minivintsImage4,
                title: orderVersion.minivints_label_4,
                index: 3
            },
            {
                original: minivintsImage5,
                thumbnail: minivintsImage5,
                title: orderVersion.minivints_label_5,
                index: 4
            },
            {
                original: minivintsImage6,
                thumbnail: minivintsImage6,
                title: orderVersion.minivints_label_6,
                index: 5
            },
            {
                original: minivintsImage7,
                thumbnail: minivintsImage7,
                title: orderVersion.minivints_label_7,
                index: 6
            },
            {
                original: minivintsImage8,
                thumbnail: minivintsImage8,
                title: orderVersion.minivints_label_8,
                index: 7
            },
            {
                original: minivintsImage9,
                thumbnail: minivintsImage9,
                title: orderVersion.minivints_label_9,
                index: 8
            },
            {
                original: minivintsImage10,
                thumbnail: minivintsImage10,
                title: orderVersion.minivints_label_10,
                index: 9
            }
        ];

        const templateImages = [
            {
                original: templateImage1,
                thumbnail: templateImage1,
                title: orderVersion.template_label_1,
                index: 0
            },
            {
                original: templateImage2,
                thumbnail: templateImage2,
                title: orderVersion.template_label_2,
                index: 1
            },
            {
                original: templateImage3,
                thumbnail: templateImage3,
                title: orderVersion.template_label_3,
                index: 2
            },
            {
                original: templateImage4,
                thumbnail: templateImage4,
                title: orderVersion.template_label_4,
                index: 3
            },
            {
                original: templateImage5,
                thumbnail: templateImage5,
                title: orderVersion.template_label_5,
                index: 4
            },
            {
                original: templateImage6,
                thumbnail: templateImage6,
                title: orderVersion.template_label_6,
                index: 5
            },
            {
                original: templateImage7,
                thumbnail: templateImage7,
                title: orderVersion.template_label_7,
                index: 6
            },
            {
                original: templateImage8,
                thumbnail: templateImage8,
                title: orderVersion.template_label_8,
                index: 7
            },
            {
                original: templateImage9,
                thumbnail: templateImage9,
                title: orderVersion.template_label_9,
                index: 8
            },
            {
                original: templateImage10,
                thumbnail: templateImage10,
                title: orderVersion.template_label_10,
                index: 9
            }
        ];

        const toolImages = [
            {
                original: toolImage1,
                thumbnail: toolImage1,
                title: orderVersion.tool_label_1,
                index: 0
            },
            {
                original: toolImage2,
                thumbnail: toolImage2,
                title: orderVersion.tool_label_2,
                index: 1
            },
            {
                original: toolImage3,
                thumbnail: toolImage3,
                title: orderVersion.tool_label_3,
                index: 2
            },
            {
                original: toolImage4,
                thumbnail: toolImage4,
                title: orderVersion.tool_label_4,
                index: 3
            },
            {
                original: toolImage5,
                thumbnail: toolImage5,
                title: orderVersion.tool_label_5,
                index: 4
            },
            {
                original: toolImage6,
                thumbnail: toolImage6,
                title: orderVersion.tool_label_6,
                index: 5
            },
            {
                original: toolImage7,
                thumbnail: toolImage7,
                title: orderVersion.tool_label_7,
                index: 6
            },
            {
                original: toolImage8,
                thumbnail: toolImage8,
                title: orderVersion.tool_label_8,
                index: 7
            },
            {
                original: toolImage9,
                thumbnail: toolImage9,
                title: orderVersion.tool_label_9,
                index: 8
            },
            {
                original: toolImage10,
                thumbnail: toolImage10,
                title: orderVersion.tool_label_10,
                index: 9
            }
        ];

        const tool2dImages = [
            {
                original: tool2dImage1,
                thumbnail: tool2dImage1,
                title: orderVersion.tool_2d_label_1,
                index: 0
            },
            {
                original: tool2dImage2,
                thumbnail: tool2dImage2,
                title: orderVersion.tool_2d_label_2,
                index: 1
            },
            {
                original: tool2dImage3,
                thumbnail: tool2dImage3,
                title: orderVersion.tool_2d_label_3,
                index: 2
            },
            {
                original: tool2dImage4,
                thumbnail: tool2dImage4,
                title: orderVersion.tool_2d_label_4,
                index: 3
            },
            {
                original: tool2dImage5,
                thumbnail: tool2dImage5,
                title: orderVersion.tool_2d_label_5,
                index: 4
            },
            {
                original: tool2dImage6,
                thumbnail: tool2dImage6,
                title: orderVersion.tool_2d_label_6,
                index: 5
            },
            {
                original: tool2dImage7,
                thumbnail: tool2dImage7,
                title: orderVersion.tool_2d_label_7,
                index: 6
            },
            {
                original: tool2dImage8,
                thumbnail: tool2dImage8,
                title: orderVersion.tool_2d_label_8,
                index: 7
            },
            {
                original: tool2dImage9,
                thumbnail: tool2dImage9,
                title: orderVersion.tool_2d_label_9,
                index: 8
            },
            {
                original: tool2dImage10,
                thumbnail: tool2dImage10,
                title: orderVersion.tool_2d_label_10,
                index: 9
            }
        ];

        const vsImages = [
            {
                original: vsImage1,
                thumbnail: vsImage1,
                title: orderVersion.vs_label_1,
                index: 0
            },
            {
                original: vsImage2,
                thumbnail: vsImage2,
                title: orderVersion.vs_label_2,
                index: 1
            },
            {
                original: vsImage3,
                thumbnail: vsImage3,
                title: orderVersion.vs_label_3,
                index: 2
            },
            {
                original: vsImage4,
                thumbnail: vsImage4,
                title: orderVersion.vs_label_4,
                index: 3
            },
            {
                original: vsImage5,
                thumbnail: vsImage5,
                title: orderVersion.vs_label_5,
                index: 4
            },
            {
                original: vsImage6,
                thumbnail: vsImage6,
                title: orderVersion.vs_label_6,
                index: 5
            },
            {
                original: vsImage7,
                thumbnail: vsImage7,
                title: orderVersion.vs_label_7,
                index: 6
            },
            {
                original: vsImage8,
                thumbnail: vsImage8,
                title: orderVersion.vs_label_8,
                index: 7
            },
            {
                original: vsImage9,
                thumbnail: vsImage9,
                title: orderVersion.vs_label_9,
                index: 8
            },
            {
                original: vsImage10,
                thumbnail: vsImage10,
                title: orderVersion.vs_label_10,
                index: 9
            },
            {
                original: vsImage11,
                thumbnail: vsImage11,
                title: orderVersion.vs_label_11,
                index: 10
            },
            {
                original: vsImage12,
                thumbnail: vsImage12,
                title: orderVersion.vs_label_12,
                index: 11
            },
            {
                original: vsImage13,
                thumbnail: vsImage13,
                title: orderVersion.vs_label_13,
                index: 12
            },
            {
                original: vsImage14,
                thumbnail: vsImage14,
                title: orderVersion.vs_label_14,
                index: 13
            },
            {
                original: vsImage15,
                thumbnail: vsImage15,
                title: orderVersion.vs_label_15,
                index: 14
            }
        ];

        const splintImages = [
            {
                original: splintImage1,
                thumbnail: splintImage1,
                title: orderVersion.splint_label_1,
                index: 0
            },
            {
                original: splintImage2,
                thumbnail: splintImage2,
                title: orderVersion.splint_label_2,
                index: 1
            },
            {
                original: splintImage3,
                thumbnail: splintImage3,
                title: orderVersion.splint_label_3,
                index: 2
            },
            {
                original: splintImage4,
                thumbnail: splintImage4,
                title: orderVersion.splint_label_4,
                index: 3
            },
            {
                original: splintImage5,
                thumbnail: splintImage5,
                title: orderVersion.splint_label_5,
                index: 4
            },
            {
                original: splintImage6,
                thumbnail: splintImage6,
                title: orderVersion.splint_label_6,
                index: 5
            },
            {
                original: splintImage7,
                thumbnail: splintImage7,
                title: orderVersion.splint_label_7,
                index: 6
            },
            {
                original: splintImage8,
                thumbnail: splintImage8,
                title: orderVersion.splint_label_8,
                index: 7
            },
            {
                original: splintImage9,
                thumbnail: splintImage9,
                title: orderVersion.splint_label_9,
                index: 8
            },
            {
                original: splintImage10,
                thumbnail: splintImage10,
                title: orderVersion.splint_label_10,
                index: 9
            }
        ];

        const images = [
            {
                original: img1,
                thumbnail: img1,
                title: orderVersion.label_1,
                index: 0
            },
            {
                original: img2,
                thumbnail: img2,
                title: orderVersion.label_2,
                index: 1
            },
            {
                original: img3,
                thumbnail: img3,
                title: orderVersion.label_3,
                index: 2
            },
            {
                original: img4,
                thumbnail: img4,
                title: orderVersion.label_4,
                index: 3
            },
            {
                original: img5,
                thumbnail: img5,
                title: orderVersion.label_5,
                index: 4
            }
        ];

        const options = [
            {
                label: texts.equipment.value,
                value: 'tool',
                disabled: stl3 === ''
            },
            {
                label: texts.template.value,
                value: 'template',
                disabled: stl4 === ''
            },
            {
                label: texts.splint.value,
                value: 'splint',
                disabled: stl20 === ''
            },
            // { label: texts.equipment.value, value: 'tool' },
            {
                label: isSlicesAvailable
                    ? texts.attachments.value
                    : texts.braces.value,
                value: 'braces',
                disabled: stl18 === '' && stl19 === '' && !isSlicesAvailable
            },
            // { label: texts.template.value, value: 'template' },
            { label: texts.none.value, value: 'none' }
        ];

        const getDefaultSelectedModel = (
            tool: string,
            template: string,
            bracesUpper: string,
            bracesLower: string,
            splint: string
        ) => {
            let result = 'none';
            if (tool !== '') {
                result = 'tool';
            } else if (template !== '') {
                result = 'template';
            } else if (bracesUpper !== '' || bracesLower !== '') {
                result = 'braces';
            } else if (splint !== '') {
                result = 'splint';
            }

            return result;
        };

        // const modeValue = shape.mode;
        let stlViewer;
        if (
            stl1 !== '' ||
            stl2 !== '' ||
            stl3 !== '' ||
            stl4 !== '' ||
            stl5 !== '' ||
            stl6 !== '' ||
            stl18 !== '' ||
            stl19 !== '' ||
            stl20 !== '' ||
            isSlicesAvailable
        ) {
            stlViewer = (
                <RowStyled gutter={16}>
                    <Col md={24} sm={24}>
                        <RowStyled
                            gutter={16}
                            style={{ padding: '32px 0 16px' }}
                        >
                            <Col md={24} sm={24} xs={24}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        minWidth: '515px'
                                    }}
                                >
                                    <ParamsTitle>
                                        {texts['order.3d.object'].value}:
                                    </ParamsTitle>
                                    <Radio.Group
                                        style={{ maxWidth: 300 }}
                                        options={options}
                                        onChange={onChangeMode}
                                        defaultValue={getDefaultSelectedModel(
                                            stl3,
                                            stl4,
                                            stl18,
                                            stl19,
                                            stl20
                                        )}
                                        value={shape.mode}
                                    />
                                </div>
                                <ModelUpperPanel>
                                    <ParamsTitle
                                        style={{ textAlign: 'center' }}
                                    >
                                        {texts.model.value}:
                                    </ParamsTitle>
                                    <Button
                                        disabled={
                                            stl1 === '' &&
                                            stl7 === '' &&
                                            !isSlicesAvailable
                                        }
                                        style={{ height: 64, marginLeft: 28 }}
                                        type={
                                            shape.upper ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelUpper}
                                    >
                                        <UpperJawIcon
                                            style={{
                                                color: shape.upper
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.upper_jaw.value}</div>
                                    </Button>
                                </ModelUpperPanel>
                                <div style={{ marginTop: 4 }}>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            minWidth: '515px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'inline-block'
                                            }}
                                        >
                                            <ParamsTitle>
                                                {
                                                    texts['order.3d.parameters']
                                                        .value
                                                }
                                                :
                                            </ParamsTitle>
                                            <div style={{ marginTop: 10 }}>
                                                <Checkbox
                                                    disabled={stl2 === ''}
                                                    checked={
                                                        stl2 === ''
                                                            ? false
                                                            : shape.minivintsVisible
                                                    }
                                                    onChange={onChangeMinivints}
                                                >
                                                    {texts.minivints.value}
                                                </Checkbox>
                                                <Checkbox
                                                    disabled={stl6 === ''}
                                                    checked={
                                                        stl6 === ''
                                                            ? false
                                                            : shape.extraVisible
                                                    }
                                                    onChange={onChangeExtra}
                                                >
                                                    {texts.addition.value}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    onChange={onChangeBefore}
                                                    checked={shape.before}
                                                    style={{ width: 111 }}
                                                    disabled={isSlicesAvailable}
                                                >
                                                    {texts.before.value}
                                                </Checkbox>
                                                <Checkbox
                                                    disabled={
                                                        stl7 === '' &&
                                                        stl9 === '' &&
                                                        stl11 === '' &&
                                                        stl13 === '' &&
                                                        stl19 === ''
                                                    }
                                                    onChange={onChangeAfter}
                                                    checked={shape.after}
                                                >
                                                    {texts.after.value}
                                                </Checkbox>
                                            </div>
                                        </div>
                                        <MaxillaryUpperPanel
                                            style={{
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                width: 180
                                            }}
                                        >
                                            <ParamsTitle
                                                style={{
                                                    display:
                                                        stl10 === '' &&
                                                        stl11 === '' &&
                                                        !isSlicesAvailable &&
                                                        stl12 === '' &&
                                                        stl13 === ''
                                                            ? 'none'
                                                            : 'block'
                                                }}
                                            >
                                                {
                                                    texts[
                                                        'stl.maxillary.opacity'
                                                    ].value
                                                }
                                                :
                                            </ParamsTitle>
                                            <Slider
                                                marks={{ 0: '0', 100: '100' }}
                                                style={{
                                                    display:
                                                        stl10 === '' &&
                                                        stl11 === '' &&
                                                        !isSlicesAvailable &&
                                                        stl12 === '' &&
                                                        stl13 === ''
                                                            ? 'none'
                                                            : 'block'
                                                }}
                                                step={5}
                                                value={shape.maxillaryOpacity}
                                                onChange={(value: any) =>
                                                    onChangeOpacity(
                                                        'maxillary',
                                                        value
                                                    )
                                                }
                                            />
                                        </MaxillaryUpperPanel>
                                    </div>
                                    <ModelLowerButon
                                        disabled={
                                            stl8 === '' &&
                                            stl9 === '' &&
                                            !isSlicesAvailable
                                        }
                                        type={
                                            shape.lower ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelLower}
                                    >
                                        <LowerJawIcon
                                            style={{
                                                color: shape.lower
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.lower_jaw.value}</div>
                                    </ModelLowerButon>
                                </div>
                                <ModelBottomPanel>
                                    <MaxillaryBottomPanel
                                        style={{
                                            display: 'block',
                                            verticalAlign: 'top',
                                            width: 180
                                        }}
                                    >
                                        <ParamsTitle
                                            style={{
                                                display:
                                                    stl10 === '' &&
                                                    stl11 === '' &&
                                                    stl12 === '' &&
                                                    !isSlicesAvailable &&
                                                    stl13 === ''
                                                        ? 'none'
                                                        : 'block'
                                            }}
                                        >
                                            {
                                                texts['stl.maxillary.opacity']
                                                    .value
                                            }
                                            :
                                        </ParamsTitle>
                                        <Slider
                                            marks={{ 0: '0', 100: '100' }}
                                            style={{
                                                display:
                                                    stl10 === '' &&
                                                    stl11 === '' &&
                                                    stl12 === '' &&
                                                    !isSlicesAvailable &&
                                                    stl13 === ''
                                                        ? 'none'
                                                        : 'block'
                                            }}
                                            step={5}
                                            value={shape.maxillaryOpacity}
                                            onChange={(value: any) =>
                                                onChangeOpacity(
                                                    'maxillary',
                                                    value
                                                )
                                            }
                                        />
                                    </MaxillaryBottomPanel>
                                    <ParamsTitle>
                                        {texts.model.value}:
                                    </ParamsTitle>
                                    <Button
                                        disabled={
                                            stl1 === '' &&
                                            stl7 === '' &&
                                            !isSlicesAvailable
                                        }
                                        style={{ height: 64 }}
                                        type={
                                            shape.upper ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelUpper}
                                    >
                                        <UpperJawIcon
                                            style={{
                                                color: shape.upper
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.upper_jaw.value}</div>
                                    </Button>
                                    <Button
                                        style={{
                                            height: 64,
                                            display: 'inline-block',
                                            marginLeft: 16,
                                            verticalAlign: 'top'
                                        }}
                                        disabled={
                                            stl8 === '' &&
                                            stl9 === '' &&
                                            !isSlicesAvailable
                                        }
                                        type={
                                            shape.lower ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelLower}
                                    >
                                        <LowerJawIcon
                                            style={{
                                                color: shape.lower
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.lower_jaw.value}</div>
                                    </Button>
                                </ModelBottomPanel>
                            </Col>
                            <Col
                                md={24}
                                sm={24}
                                xs={24}
                                // hidden={!isSlicesAvailable}
                            >
                                <div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            minWidth: '300px'
                                        }}
                                    >
                                        <ParamsTitle>{`${texts['view.title'].value}:`}</ParamsTitle>
                                        <Button.Group size="small">
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        Math.PI / 2,
                                                        Math.PI,
                                                        Math.PI
                                                    ]);
                                                }}
                                            >
                                                {texts['view.top'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        0,
                                                        Math.PI / 2,
                                                        0
                                                    ]);
                                                }}
                                            >
                                                {texts['view.right'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([0, 0, 0]);
                                                }}
                                            >
                                                {texts['view.front'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        0,
                                                        -Math.PI / 2,
                                                        0
                                                    ]);
                                                }}
                                            >
                                                {texts['view.left'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        -Math.PI / 2,
                                                        Math.PI,
                                                        Math.PI
                                                    ]);
                                                }}
                                            >
                                                {texts['view.bottom'].value}
                                            </Button>
                                        </Button.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top'
                                        }}
                                    >
                                        <ParamsTitle>{`${texts['view.background'].value}:`}</ParamsTitle>
                                        <ThemeSwitch
                                            style={{
                                                background: '#787878'
                                            }}
                                            checkedChildren="🌜"
                                            unCheckedChildren="🌞"
                                            onChange={(value: boolean) => {
                                                setTheme(
                                                    value ? 'dark' : 'light'
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={24} sm={24} xs={24}>
                                <OrderStlViewSettings
                                    hideButtons={false}
                                    texts={texts}
                                    header={texts['menu.settings'].value}
                                    modelColor={
                                        stlSettings
                                            ? stlSettings.model_color
                                            : '#e6bf8b'
                                    }
                                    modelAfterColor={shape.modelAfterColor}
                                    modelShineness={shape.modelShineness}
                                    modelAfterShineness={
                                        shape.modelAfterShineness
                                    }
                                    templateColor={
                                        stlSettings
                                            ? stlSettings.template_color
                                            : '#e6e1d6'
                                    }
                                    templateShineness={shape.templateShineness}
                                    minivintsColor={
                                        stlSettings
                                            ? stlSettings.minivints_color
                                            : '#009a63'
                                    }
                                    minivintsShineness={
                                        shape.minivintsShineness
                                    }
                                    toolColor={
                                        stlSettings
                                            ? stlSettings.tool_color
                                            : '#ffffff'
                                    }
                                    toolShineness={shape.toolShineness}
                                    trayColor={
                                        stlSettings
                                            ? stlSettings.tray_color
                                            : '#ffffff'
                                    }
                                    trayShineness={shape.trayShineness}
                                    trayOpacity={shape.trayOpacity}
                                    splintColor={
                                        stlSettings
                                            ? stlSettings.splint_color
                                                ? stlSettings.splint_color
                                                : '#aeccdc'
                                            : '#aeccdc'
                                    }
                                    splintShineness={shape.splintShineness}
                                    splintOpacity={shape.splintOpacity}
                                    maxillaryColor={
                                        stlSettings
                                            ? stlSettings.maxillary_color
                                                ? stlSettings.maxillary_color
                                                : '#e8a0a0'
                                            : '#e8a0a0'
                                    }
                                    maxillaryShineness={
                                        shape.maxillaryShineness
                                    }
                                    onChangeColor={onChangeColor}
                                    onDefault={onDefault}
                                    onChangeShineness={onChangeShineness}
                                    onChangeOpacity={onChangeOpacity}
                                    onSaveSettings={() => {
                                        dispatch(
                                            onSaveStlSettings(
                                                shape.modelColor,
                                                shape.modelAfterColor,
                                                shape.minivintsColor,
                                                shape.templateColor,
                                                shape.toolColor,
                                                shape.trayColor,
                                                shape.modelShineness,
                                                shape.modelAfterShineness,
                                                shape.minivintsShineness,
                                                shape.templateShineness,
                                                shape.toolShineness,
                                                shape.trayShineness,
                                                shape.trayOpacity,
                                                shape.splintColor,
                                                shape.splintShineness,
                                                shape.splintOpacity,
                                                shape.maxillaryColor,
                                                shape.maxillaryShineness
                                            )
                                        );
                                        message.success(
                                            texts['stl.settings.applied'].value
                                        );
                                    }}
                                />
                            </Col>
                        </RowStyled>
                        <Shape
                            theme={theme}
                            before={shape.before}
                            after={shape.after}
                            upper={shape.upper}
                            lower={shape.lower}
                            maxillaryUpper={stl10}
                            maxillaryUpperAfter={stl11}
                            maxillaryLower={stl12}
                            maxillaryLowerAfter={stl13}
                            maxillaryVisible={shape.maxillaryVisible}
                            maxillaryOpacity={shape.maxillaryOpacity}
                            maxillaryColor={shape.maxillaryColor}
                            maxillaryShineness={shape.maxillaryShineness}
                            model={stl1}
                            modelLower={stl8}
                            modelAfter={stl7}
                            modelLowerAfter={stl9}
                            modelVisible={shape.modelVisible}
                            modelColor={shape.modelColor}
                            modelShineness={shape.modelShineness}
                            modelAfterColor={shape.modelAfterColor}
                            modelAfterShineness={shape.modelAfterShineness}
                            extra={stl6}
                            extraVisible={shape.extraVisible}
                            extraColor={shape.extraColor}
                            extraShineness={shape.extraShineness}
                            minivints={stl2}
                            minivintsVisible={shape.minivintsVisible}
                            minivintsColor={shape.minivintsColor}
                            minivintsShineness={shape.minivintsShineness}
                            tool={stl3}
                            toolVisible={shape.toolVisible}
                            toolColor={shape.toolColor}
                            toolShineness={shape.toolShineness}
                            bracesUpper={stl18}
                            bracesLower={stl19}
                            bracesVisible={shape.bracesVisible}
                            bracesShineness={shape.bracesShineness}
                            bracesColor={shape.bracesColor}
                            template={stl4}
                            templateVisible={shape.templateVisible}
                            templateColor={shape.templateColor}
                            templateShineness={shape.templateShineness}
                            tray={stl5}
                            trayVisible={shape.trayVisible}
                            trayColor={shape.trayColor}
                            trayShineness={shape.trayShineness}
                            trayOpacity={shape.trayOpacity}
                            splint={stl20}
                            splintVisible={shape.splintVisible}
                            splintColor={shape.splintColor}
                            splintShineness={shape.splintShineness}
                            splintOpacity={shape.splintOpacity}
                            bordersVisible={shape.bordersVisible}
                            slices={slices}
                            sliceIndex={`${sliceIndex}`}
                            sceneView={sceneView}
                            showOverLayer={showOverLayer}
                            onCompletelyLoaded={() => {
                                setSlicesCompletelyLoaded(true);
                            }}
                        />
                        <SliceSlider
                            // value={sliceIndex}
                            disabled={!slicesCompletelyLoaded}
                            hidden={!isSlicesAvailable}
                            slices={slices}
                            showOverLayer={showOverLayer}
                            onChange={(sliderValue: any) => {
                                setSliceIndex(sliderValue);
                            }}
                            onShowOverLayer={(showLayerValue: any) => {
                                setShowOverLayer(showLayerValue);
                            }}
                        />
                    </Col>
                </RowStyled>
            );
        }

        const menu = (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={(e) => onEditClick(e, order, orderVersion)}
                >
                    <Icon type="edit" />
                    {texts.edit.value}
                </Menu.Item>
                <Menu.Item key="2" onClick={(e) => onNewVersionClick(e, order)}>
                    <Icon type="plus" />
                    {texts.create.value}
                </Menu.Item>
                <Menu.Item key="3" onClick={(e) => onDeleteClick(e, order)}>
                    <Icon type="delete" />
                    {texts.delete.value}
                </Menu.Item>
            </Menu>
        );

        const operations = (
            <ActionPanel>
                <ButtonAction
                    onClick={() => setAlignersInfoVisible(!alignersInfoVisible)}
                    // onClick={() => onCopy(id, revision, texts, order)}
                    icon="info-circle"
                    size="small"
                    type="primary"
                    hidden={
                        order.status_aligners === 'NONE' ||
                        !order.status_aligners
                    }
                >
                    {texts['order.aligners.addition'].value}
                </ButtonAction>
                <ButtonAction
                    onClick={() => onCopy(id, revision, texts, order)}
                    icon="share-alt"
                    size="small"
                >
                    {texts.share.value}
                </ButtonAction>
                <Dropdown overlay={menu}>
                    <Button icon="ellipsis" size="small" />
                </Dropdown>
            </ActionPanel>
        );

        content = (
            <>
                {/* <Head>
                    <title>{this.getTitle(order, texts)}</title>
                </Head> */}
                <Row gutter={16}>
                    <Col lg={6} md={24}>
                        <OrderCardParams
                            texts={texts}
                            scope="manager"
                            order={order}
                            revision={revision}
                            orderVersion={orderVersion}
                            showTimeline
                        />
                    </Col>
                    <Col lg={18} md={24}>
                        <TabsContainer>
                            <Tabs
                                defaultActiveKey={activeTab}
                                tabBarExtraContent={operations}
                            >
                                <TabsBody
                                    tab={texts['order.details'].value}
                                    key="1"
                                >
                                    <ContentLayout>
                                        <ContentStyled>
                                            <AlignersInfo order={order} />
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        items={images}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.minivints
                                                                .value
                                                        }
                                                        items={minivintsImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.template.value
                                                        }
                                                        items={templateImages}
                                                        video={video}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.equipment
                                                                .value
                                                        }
                                                        items={toolImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.equipment2d
                                                                .value
                                                        }
                                                        items={tool2dImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.virtual_setup
                                                                .value
                                                        }
                                                        items={vsImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.splint.value
                                                        }
                                                        items={splintImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            {stlViewer}
                                            <Divider />
                                            {gif && (
                                                <OrderGifCard
                                                    title={
                                                        texts.equipment_check
                                                            .value
                                                    }
                                                    mode="3d"
                                                    orderId={idFromQuery}
                                                    revisionId={orderVersion.id}
                                                />
                                            )}
                                            {gif2d && (
                                                <OrderGifCard
                                                    title={
                                                        texts.equipment2d_check
                                                            .value
                                                    }
                                                    mode="2d"
                                                    orderId={idFromQuery}
                                                    revisionId={orderVersion.id}
                                                />
                                            )}
                                            {gif_splint && (
                                                <OrderGifCard
                                                    title={
                                                        texts.splint_check.value
                                                    }
                                                    mode="splint"
                                                    orderId={idFromQuery}
                                                    revisionId={orderVersion.id}
                                                />
                                            )}
                                            <Divider />
                                        </ContentStyled>
                                    </ContentLayout>
                                </TabsBody>
                                <TabsBody
                                    tab={texts['order.feedback'].value}
                                    key="2"
                                >
                                    <OrderComments
                                        texts={texts}
                                        orderId={id}
                                        revisionId={revision}
                                    />
                                </TabsBody>
                                <TabsBody
                                    tab={texts['order.internalfeedback'].value}
                                    key="3"
                                >
                                    <OrderInternalComments
                                        texts={texts}
                                        orderId={id}
                                        revisionId={revision}
                                    />
                                </TabsBody>
                                <TabsBody
                                    tab={texts['order.labels'].value}
                                    key="4"
                                >
                                    <LabelsPrinter
                                        order={order}
                                        texts={texts}
                                        slices={slices}
                                    />
                                </TabsBody>
                            </Tabs>
                        </TabsContainer>
                    </Col>
                </Row>
                <AlignersInfoModal
                    visible={alignersInfoVisible}
                    orderId={idFromQuery}
                    slices={slices}
                    handleCancel={() => setAlignersInfoVisible(false)}
                    handleOk={() => {
                        dispatch(selectOrder(id, revision));
                        setAlignersInfoVisible(false);
                    }}
                />
            </>
        );
    }
    return (
        <>
            {content}
            <CreateOrderModalForm />
            <NewOrderModalForm />
        </>
    );
};
