import React, { Component } from 'react';
import { orderBy, find, map, uniq, filter } from 'lodash';
import {
    Badge,
    Button,
    Card,
    Divider,
    Icon,
    Select,
    Timeline,
    Tooltip
} from 'antd';
import { NavLink } from 'react-router-dom';
// import Router from 'next/router';
import moment from 'moment';
import styled from 'styled-components';
import getStatus from '../../utils/status';
import { isEmpty } from '../../utils/common';

const { Option } = Select;

const CardTitle = styled.div`
    font-weight: 300;
    text-align: center;

    p {
        margin: 0;
    }
`;

const CardSubTitle = styled.div`
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 24px;
`;

const CardText = styled.div`
    font-weight: 400;
`;

const FieldLabelInline = styled.div`
    min-width: 98px;
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
`;

const Field = styled.div`
    font-size: 13px;
    font-weight: 400;
    padding: 4px 0;
`;

const FieldLabel = styled.div`
    min-width: 98px;
    color: rgba(0, 0, 0, 0.45);
`;

const TimelineItem = styled(Timeline.Item)`
    font-weight: 400;
`;

class OrderCardParams extends Component {
    constructor(props) {
        super(props);
        let { version } = props.order;
        if (props.revision !== 'latest') {
            version = find(
                props.order.orderversions,
                (item) => `${item.id}` === props.revision
            ).version;
        }
        this.state = {
            currentVersion: version
        };
    }

    static getUsername(lastName, firstName, middleName) {
        let username = lastName;
        if (!isEmpty(firstName)) {
            username += ` ${firstName}`;
        }

        if (!isEmpty(middleName)) {
            username += ` ${middleName}`;
        }
        return username;
    }

    static getFieldLabel(label) {
        return `${label}:`;
    }

    onVersionChange(currentVersion) {
        const { order, scope } = this.props;
        const orderVersions = order.orderversions;
        const revision = orderBy(
            filter(orderVersions, { version: parseInt(currentVersion, 10) }),
            ['design_date'],
            ['desc']
        );
        window.location.href = `/${scope}/order-details?id=${order.id}&revision=${revision[0].id}`;
        // Router.push(`/${scope}/order-details?id=${order.id}&revision=${revision[0].id}`);
        // this.setState({
        //     currentVersion: parseInt(currentVersion),
        // });
    }

    render() {
        const {
            order,
            revision,
            scope,
            orderVersion,
            texts,
            mode,
            showTimeline,
            onApproveOrderStage
        } = this.props;
        const { currentVersion } = this.state;
        const { patient } = order;
        const orderversions = orderBy(
            order.orderversions,
            ['design_date'],
            ['asc']
        );
        const currentOrderVersions = orderBy(
            filter(orderversions, { version: parseInt(currentVersion, 10) }),
            ['design_date'],
            ['desc']
        );
        const latestRevision = currentOrderVersions[0];
        const currentRevision = orderVersion;

        const title = (
            <CardTitle>
                <p>{texts['order.information'].value}</p>
            </CardTitle>
        );

        const versionsRaw = uniq(map(orderversions, 'version'));
        const versions = [];
        for (let i = 0; i < versionsRaw.sort((a, b) => a - b).length; i += 1) {
            versions.push(
                <Option key={`${versionsRaw[i]}`}>{`${versionsRaw[i]}`}</Option>
            );
        }

        const doctor = OrderCardParams.getUsername(
            order.doctor.last_name,
            order.doctor.first_name,
            order.doctor.middle_name
        );
        return (
            <Card title={title} bordered>
                <Field hidden={!showTimeline}>
                    <FieldLabel>
                        {OrderCardParams.getFieldLabel(
                            texts['order.bill'].value
                        )}
                    </FieldLabel>
                    <CardText>{order.bill || '-'}</CardText>
                </Field>
                <Field>
                    <FieldLabel>
                        {OrderCardParams.getFieldLabel(
                            texts['order.doctor'].value
                        )}
                    </FieldLabel>
                    <CardText>{doctor || '-'}</CardText>
                </Field>
                <Field>
                    <FieldLabel>
                        {OrderCardParams.getFieldLabel(
                            texts['order.patient'].value
                        )}
                    </FieldLabel>
                    <CardText>{patient || '-'}</CardText>
                </Field>
                <Divider dashed />
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.status.value)}
                    </FieldLabelInline>
                    <Badge
                        color={getStatus(order.status).color}
                        text={
                            texts[`order.status.${order.status.toLowerCase()}`]
                                .value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status === 'READYTOSEND' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(currentRevision, 'all', true)
                        }
                    >
                        {texts.accept.value}
                    </Button>
                </Field>
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.minivints.value)}
                    </FieldLabelInline>
                    <Badge
                        color={
                            getStatus(currentRevision.status_minivints).color
                        }
                        text={
                            texts[
                                `order.status.${currentRevision.status_minivints.toLowerCase()}`
                            ].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_minivints ===
                                'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'minivints',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.template.value)}
                    </FieldLabelInline>
                    <Badge
                        color={getStatus(currentRevision.status_template).color}
                        text={
                            texts[
                                `order.status.${currentRevision.status_template.toLowerCase()}`
                            ].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_template === 'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'template',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                {/* Equipment 3d */}
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.equipment.value)}
                    </FieldLabelInline>
                    <Badge
                        color={
                            getStatus(currentRevision.status_equipment).color
                        }
                        text={
                            texts[
                                `order.status.${currentRevision.status_equipment.toLowerCase()}`
                            ].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_equipment ===
                                'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'appliance',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                {/* Equipment 2d */}
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.equipment2d.value)}
                    </FieldLabelInline>
                    <Badge
                        color={
                            getStatus(currentRevision.status_equipment_2d).color
                        }
                        text={
                            currentRevision.status_equipment_2d
                                ? texts[
                                      `order.status.${currentRevision.status_equipment_2d.toLowerCase()}`
                                  ].value
                                : texts[`order.status.none`].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_equipment_2d ===
                                'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'appliance2d',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                {/* Splint */}
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.splint.value)}
                    </FieldLabelInline>
                    <Badge
                        color={getStatus(currentRevision.status_splint).color}
                        text={
                            currentRevision.status_splint
                                ? texts[
                                      `order.status.${currentRevision.status_splint.toLowerCase()}`
                                  ].value
                                : texts[`order.status.none`].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_splint === 'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'splint',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                {/* Braces */}
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.braces.value)}
                    </FieldLabelInline>
                    <Badge
                        color={getStatus(currentRevision.status_braces).color}
                        text={
                            currentRevision.status_braces
                                ? texts[
                                      `order.status.${currentRevision.status_braces.toLowerCase()}`
                                  ].value
                                : texts[`order.status.none`].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_braces === 'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'braces',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                {/* Aligners */}
                <Field>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.aligners.value)}
                    </FieldLabelInline>
                    <Badge
                        color={getStatus(currentRevision.status_aligners).color}
                        text={
                            currentRevision.status_aligners
                                ? texts[
                                      `order.status.${currentRevision.status_aligners.toLowerCase()}`
                                  ].value
                                : texts[`order.status.none`].value
                        }
                    />
                </Field>
                <Field
                    style={{
                        display:
                            currentRevision.status_aligners === 'ON_APPROVAL' &&
                            scope === 'doctor' &&
                            currentRevision.id === latestRevision.id &&
                            mode !== 'share'
                                ? 'block'
                                : 'none'
                    }}
                >
                    <FieldLabelInline />
                    <Button
                        type="danger"
                        size="small"
                        onClick={() =>
                            onApproveOrderStage(
                                currentRevision,
                                'aligners',
                                false
                            )
                        }
                    >
                        {texts.approve.value}
                    </Button>
                </Field>
                <Divider dashed />
                <Field hidden={!showTimeline}>
                    <FieldLabelInline>
                        {OrderCardParams.getFieldLabel(texts.version.value)}
                    </FieldLabelInline>
                    <Select
                        defaultValue={`${currentVersion}`}
                        onChange={(e) => this.onVersionChange(e)}
                    >
                        {versions}
                    </Select>
                </Field>
                <Divider hidden={!showTimeline} dashed />
                <CardSubTitle hidden={!showTimeline}>
                    {OrderCardParams.getFieldLabel(texts.history.value)}
                </CardSubTitle>
                <Timeline reverse hidden={!showTimeline}>
                    {orderversions.map((object, i) => {
                        let visibleForDoctor = true;
                        if (!object.public && scope === 'doctor') {
                            visibleForDoctor = false;
                        }

                        // if (
                        //     doctorVisibleStatuses.indexOf(
                        //         object.status_minivints
                        //     ) < 0 ||
                        //     doctorVisibleStatuses.indexOf(
                        //         object.status_template
                        //     ) < 0 ||
                        //     doctorVisibleStatuses.indexOf(
                        //         object.status_equipment
                        //     ) < 0
                        // ) {
                        //     visibleForDoctor = false;
                        // }

                        // if (!visibleForDoctor && scope === 'doctor') {
                        //     return;
                        // }

                        if (object.version !== currentVersion) {
                            return;
                        }
                        const revisionHref = `/${scope}/order-details?id=${order.id}&revision=${object.id}`;
                        let display =
                            i === orderversions.length - 1 &&
                            revision === 'latest'
                                ? 'block'
                                : 'none';
                        if (revision !== 'latest') {
                            display = revision === object.id ? 'block' : 'none';
                        }
                        let item = (
                            <TimelineItem
                                key={i}
                                color="red"
                                dot={
                                    <Tooltip
                                        title={
                                            texts['unavailable.doctor'].value
                                        }
                                    >
                                        <Icon
                                            type="lock"
                                            style={{ fontSize: '16px' }}
                                        />
                                    </Tooltip>
                                }
                            >
                                <NavLink to={revisionHref}>
                                    {moment(object.design_date).format(
                                        'DD/MM/YY HH:mm:ss'
                                    )}
                                </NavLink>
                                <div style={{ display }}>
                                    <Icon
                                        type="check-circle"
                                        theme="twoTone"
                                        twoToneColor="#3CBB9E"
                                    />
                                    <span>{` ${texts['order.current_version'].value}`}</span>
                                </div>
                                {object.status_minivints !== 'NONE' && (
                                    <Field>
                                        <FieldLabelInline>
                                            {OrderCardParams.getFieldLabel(
                                                texts.minivints.value
                                            )}
                                        </FieldLabelInline>
                                        <Badge
                                            color={
                                                getStatus(
                                                    object.status_minivints
                                                ).color
                                            }
                                            text={
                                                texts[
                                                    `order.status.${object.status_minivints.toLowerCase()}`
                                                ].value
                                            }
                                        />
                                    </Field>
                                )}
                                {object.status_template !== 'NONE' && (
                                    <Field>
                                        <FieldLabelInline>
                                            {OrderCardParams.getFieldLabel(
                                                texts.template.value
                                            )}
                                        </FieldLabelInline>
                                        <Badge
                                            color={
                                                getStatus(
                                                    object.status_template
                                                ).color
                                            }
                                            text={
                                                texts[
                                                    `order.status.${object.status_template.toLowerCase()}`
                                                ].value
                                            }
                                        />
                                    </Field>
                                )}
                                {object.status_equipment !== 'NONE' && (
                                    <Field>
                                        <FieldLabelInline>
                                            {OrderCardParams.getFieldLabel(
                                                texts.equipment.value
                                            )}
                                        </FieldLabelInline>
                                        <Badge
                                            color={
                                                getStatus(
                                                    object.status_equipment
                                                ).color
                                            }
                                            text={
                                                texts[
                                                    `order.status.${object.status_equipment.toLowerCase()}`
                                                ].value
                                            }
                                        />
                                    </Field>
                                )}
                                {object.status_equipment_2d &&
                                    object.status_equipment_2d !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.equipment2d.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_equipment_2d
                                                    ).color
                                                }
                                                text={
                                                    object.status_equipment_2d
                                                        ? texts[
                                                              `order.status.${object.status_equipment_2d.toLowerCase()}`
                                                          ].value
                                                        : texts[
                                                              `order.status.none`
                                                          ].value
                                                }
                                            />
                                        </Field>
                                    )}
                                {object.status_splint &&
                                    object.status_splint !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.splint.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_splint
                                                    ).color
                                                }
                                                text={
                                                    object.status_splint
                                                        ? texts[
                                                              `order.status.${object.status_splint.toLowerCase()}`
                                                          ].value
                                                        : texts[
                                                              `order.status.none`
                                                          ].value
                                                }
                                            />
                                        </Field>
                                    )}
                                {object.status_braces &&
                                    object.status_braces !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.braces.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_braces
                                                    ).color
                                                }
                                                text={
                                                    object.status_braces
                                                        ? texts[
                                                              `order.status.${object.status_braces.toLowerCase()}`
                                                          ].value
                                                        : texts[
                                                              `order.status.none`
                                                          ].value
                                                }
                                            />
                                        </Field>
                                    )}
                                {object.status_aligners &&
                                    object.status_aligners !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.aligners.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_aligners
                                                    ).color
                                                }
                                                text={
                                                    object.status_aligners
                                                        ? texts[
                                                              `order.status.${object.status_aligners.toLowerCase()}`
                                                          ].value
                                                        : texts[
                                                              `order.status.none`
                                                          ].value
                                                }
                                            />
                                        </Field>
                                    )}
                            </TimelineItem>
                        );
                        if (object.public) {
                            item = (
                                <TimelineItem key={i} color="#3CBB9E">
                                    <NavLink to={revisionHref}>
                                        {moment(object.design_date).format(
                                            'DD/MM/YY HH:mm:ss'
                                        )}
                                    </NavLink>
                                    <div style={{ display }}>
                                        <Icon
                                            type="check-circle"
                                            theme="twoTone"
                                            twoToneColor="#3CBB9E"
                                        />
                                        <span>{` ${texts['order.current_version'].value}`}</span>
                                    </div>
                                    <Field>
                                        <FieldLabelInline>
                                            {OrderCardParams.getFieldLabel(
                                                texts.status.value
                                            )}
                                        </FieldLabelInline>
                                        <Badge
                                            color={
                                                getStatus(object.status).color
                                            }
                                            text={
                                                texts[
                                                    `order.status.${object.status.toLowerCase()}`
                                                ].value
                                            }
                                        />
                                    </Field>
                                    {object.status_minivints !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.minivints.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_minivints
                                                    ).color
                                                }
                                                text={
                                                    texts[
                                                        `order.status.${object.status_minivints.toLowerCase()}`
                                                    ].value
                                                }
                                            />
                                        </Field>
                                    )}
                                    {object.status_template !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.template.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_template
                                                    ).color
                                                }
                                                text={
                                                    texts[
                                                        `order.status.${object.status_template.toLowerCase()}`
                                                    ].value
                                                }
                                            />
                                        </Field>
                                    )}
                                    {object.status_equipment !== 'NONE' && (
                                        <Field>
                                            <FieldLabelInline>
                                                {OrderCardParams.getFieldLabel(
                                                    texts.equipment.value
                                                )}
                                            </FieldLabelInline>
                                            <Badge
                                                color={
                                                    getStatus(
                                                        object.status_equipment
                                                    ).color
                                                }
                                                text={
                                                    texts[
                                                        `order.status.${object.status_equipment.toLowerCase()}`
                                                    ].value
                                                }
                                            />
                                        </Field>
                                    )}
                                    {object.status_equipment_2d &&
                                        object.status_equipment_2d !==
                                            'NONE' && (
                                            <Field>
                                                <FieldLabelInline>
                                                    {OrderCardParams.getFieldLabel(
                                                        texts.equipment2d.value
                                                    )}
                                                </FieldLabelInline>
                                                <Badge
                                                    color={
                                                        getStatus(
                                                            object.status_equipment_2d
                                                        ).color
                                                    }
                                                    text={
                                                        object.status_equipment_2d
                                                            ? texts[
                                                                  `order.status.${object.status_equipment_2d.toLowerCase()}`
                                                              ].value
                                                            : texts[
                                                                  `order.status.none`
                                                              ].value
                                                    }
                                                />
                                            </Field>
                                        )}
                                    {object.status_splint &&
                                        object.status_splint !== 'NONE' && (
                                            <Field>
                                                <FieldLabelInline>
                                                    {OrderCardParams.getFieldLabel(
                                                        texts.splint.value
                                                    )}
                                                </FieldLabelInline>
                                                <Badge
                                                    color={
                                                        getStatus(
                                                            object.status_splint
                                                        ).color
                                                    }
                                                    text={
                                                        object.status_splint
                                                            ? texts[
                                                                  `order.status.${object.status_splint.toLowerCase()}`
                                                              ].value
                                                            : texts[
                                                                  `order.status.none`
                                                              ].value
                                                    }
                                                />
                                            </Field>
                                        )}
                                    {object.status_braces &&
                                        object.status_braces !== 'NONE' && (
                                            <Field>
                                                <FieldLabelInline>
                                                    {OrderCardParams.getFieldLabel(
                                                        texts.braces.value
                                                    )}
                                                </FieldLabelInline>
                                                <Badge
                                                    color={
                                                        getStatus(
                                                            object.status_braces
                                                        ).color
                                                    }
                                                    text={
                                                        object.status_braces
                                                            ? texts[
                                                                  `order.status.${object.status_braces.toLowerCase()}`
                                                              ].value
                                                            : texts[
                                                                  `order.status.none`
                                                              ].value
                                                    }
                                                />
                                            </Field>
                                        )}
                                    {object.status_aligners &&
                                        object.status_aligners !== 'NONE' && (
                                            <Field>
                                                <FieldLabelInline>
                                                    {OrderCardParams.getFieldLabel(
                                                        texts.aligners.value
                                                    )}
                                                </FieldLabelInline>
                                                <Badge
                                                    color={
                                                        getStatus(
                                                            object.status_aligners
                                                        ).color
                                                    }
                                                    text={
                                                        object.status_aligners
                                                            ? texts[
                                                                  `order.status.${object.status_aligners.toLowerCase()}`
                                                              ].value
                                                            : texts[
                                                                  `order.status.none`
                                                              ].value
                                                    }
                                                />
                                            </Field>
                                        )}
                                </TimelineItem>
                            );
                        }
                        return item;
                    })}
                </Timeline>
            </Card>
        );
    }
}

export default OrderCardParams;
