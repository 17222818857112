import { orderBy, omit, uniq, map, filter } from 'lodash';

interface Doctor {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
}

const invisibleStatuses = [
    // 'NONE',
    'OTK',
    'PLANNED',
    'DESIGNED',
    'PRINTING',
    'PRINTED',
    'MOLDED'
];
const doctorVisibleStatuses = [
    null,
    'NONE',
    'NEW',
    'ON_APPROVAL',
    'CORRECTION',
    'APPROVED',
    'READYTOSEND',
    'READY',
    'DECLINED',
    'CLOSED'
];

export const getUserName = (doctor: Doctor) => {
    let result = doctor.last_name;
    if (doctor.first_name) {
        result += ` ${doctor.first_name[0]}.`;
    }
    if (doctor.middle_name) {
        result += ` ${doctor.middle_name[0]}.`;
    }
    return result;
};

const findNearestVisibleStatus = (
    currentVersionId: string,
    what: string,
    version: string,
    orderversions: any[],
    alreadyFound: boolean
) => {
    let versionResult = null;
    let find = alreadyFound;
    // if (!find) {
    for (let i = 0; i < orderversions.length; i += 1) {
        const object = orderversions[i];
        if (find && object.version === version) {
            let status;
            if (what === 'minivints') {
                status = object.status_minivints;
            } else if (what === 'template') {
                status = object.status_template;
            } else if (what === 'equipment') {
                status = object.status_equipment;
            } else if (what === 'equipment_2d') {
                status = object.status_equipment_2d;
            } else if (what === 'braces') {
                status = object.status_braces;
            } else if (what === 'splint') {
                status = object.status_splint;
            } else if (what === 'aligners') {
                status = object.status_aligners;
            }
            if (doctorVisibleStatuses.indexOf(status) >= 0) {
                versionResult = object;
                break;
            }
        }
        if (object.id === currentVersionId) {
            find = true;
        }
    }
    return versionResult;
};

const getOrderVersionVisibility = (
    currentOrderversion: any,
    previousOrderversion: any,
    allOrderversions: any[],
    alreadyFound: boolean
) => {
    let visibleForDoctor = true;
    const object = currentOrderversion;
    object.backup = omit(object, []);
    let newStatusVersion;
    if (
        invisibleStatuses.indexOf(object.status_minivints) >= 0 &&
        invisibleStatuses.indexOf(object.status_template) >= 0 &&
        invisibleStatuses.indexOf(object.status_equipment) >= 0 &&
        invisibleStatuses.indexOf(object.status_braces) >= 0 &&
        invisibleStatuses.indexOf(object.status_splint) >= 0 &&
        invisibleStatuses.indexOf(object.status_aligners) >= 0 &&
        invisibleStatuses.indexOf(object.status_equipment_2d) >= 0 &&
        !(
            object.status_minivints === 'NEW' &&
            object.status_template === 'NEW' &&
            object.status_equipment === 'NEW' &&
            object.status_braces === 'NEW' &&
            object.status_splint === 'NEW' &&
            object.status_aligners === 'NEW' &&
            object.status_equipment_2d === 'NEW'
        )
    ) {
        return false;
    }
    // если статус X не в [statuses] и статусы Y или Z в [statuses]
    if (
        doctorVisibleStatuses.indexOf(object.status_minivints) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_template) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment_2d) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_braces) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_aligners) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'minivints',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_minivints = newStatusVersion.status_minivints;
        object.istl_2 = newStatusVersion.istl_2;
        object.minivints_image_1 = newStatusVersion.minivints_image_1;
        object.minivints_image_2 = newStatusVersion.minivints_image_2;
        object.minivints_image_3 = newStatusVersion.minivints_image_3;
        object.minivints_image_4 = newStatusVersion.minivints_image_4;
        object.minivints_image_5 = newStatusVersion.minivints_image_5;
        object.minivints_image_6 = newStatusVersion.minivints_image_6;
        object.minivints_image_7 = newStatusVersion.minivints_image_7;
        object.minivints_image_8 = newStatusVersion.minivints_image_8;
        object.minivints_image_9 = newStatusVersion.minivints_image_9;
        object.minivints_image_10 = newStatusVersion.minivints_image_10;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_template) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_minivints) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment_2d) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_braces) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_aligners) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'template',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_template = newStatusVersion.status_template;
        object.istl_4 = newStatusVersion.istl_4;
        object.template_image_1 = newStatusVersion.template_image_1;
        object.template_image_2 = newStatusVersion.template_image_2;
        object.template_image_3 = newStatusVersion.template_image_3;
        object.template_image_4 = newStatusVersion.template_image_4;
        object.template_image_5 = newStatusVersion.template_image_5;
        object.template_image_6 = newStatusVersion.template_image_6;
        object.template_image_7 = newStatusVersion.template_image_7;
        object.template_image_8 = newStatusVersion.template_image_8;
        object.template_image_9 = newStatusVersion.template_image_9;
        object.template_image_10 = newStatusVersion.template_image_10;
        object.video = newStatusVersion.video;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_equipment) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_minivints) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_template) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment_2d) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_braces) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_aligners) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'equipment',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_equipment = newStatusVersion.status_equipment;
        object.istl_3 = newStatusVersion.istl_3;
        object.istl_5 = newStatusVersion.istl_5;
        object.istl_6 = newStatusVersion.istl_6;
        object.equipment_image_1 = newStatusVersion.equipment_image_1;
        object.equipment_image_2 = newStatusVersion.equipment_image_2;
        object.equipment_image_3 = newStatusVersion.equipment_image_3;
        object.equipment_image_4 = newStatusVersion.equipment_image_4;
        object.equipment_image_5 = newStatusVersion.equipment_image_5;
        object.equipment_image_6 = newStatusVersion.equipment_image_6;
        object.equipment_image_7 = newStatusVersion.equipment_image_7;
        object.equipment_image_8 = newStatusVersion.equipment_image_8;
        object.equipment_image_9 = newStatusVersion.equipment_image_9;
        object.equipment_image_10 = newStatusVersion.equipment_image_10;
        object.gif = newStatusVersion.gif;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_equipment_2d) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_minivints) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_template) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_braces) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_aligners) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'equipment_2d',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_equipment_2d = newStatusVersion.status_equipment_2d;
        object.equipment_2d_image_1 = newStatusVersion.equipment_2d_image_1;
        object.equipment_2d_image_2 = newStatusVersion.equipment_2d_image_2;
        object.equipment_2d_image_3 = newStatusVersion.equipment_2d_image_3;
        object.equipment_2d_image_4 = newStatusVersion.equipment_2d_image_4;
        object.equipment_2d_image_5 = newStatusVersion.equipment_2d_image_5;
        object.equipment_2d_image_6 = newStatusVersion.equipment_2d_image_6;
        object.equipment_2d_image_7 = newStatusVersion.equipment_2d_image_7;
        object.equipment_2d_image_8 = newStatusVersion.equipment_2d_image_8;
        object.equipment_2d_image_9 = newStatusVersion.equipment_2d_image_9;
        object.equipment_2d_image_10 = newStatusVersion.equipment_2d_image_10;
        object.gif2d = newStatusVersion.gif2d;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_braces) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_minivints) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_template) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment_2d) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_aligners) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'braces',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_braces = newStatusVersion.status_braces;
        object.istl_7 = newStatusVersion.istl_7;
        object.istl_9 = newStatusVersion.istl_9;
        object.istl_10 = newStatusVersion.istl_10;
        object.istl_11 = newStatusVersion.istl_11;
        object.istl_12 = newStatusVersion.istl_12;
        object.istl_13 = newStatusVersion.istl_13;
        object.istl_18 = newStatusVersion.istl_18;
        object.istl_19 = newStatusVersion.istl_19;
        object.istl_20 = newStatusVersion.istl_20;
        object.vs_image_1 = newStatusVersion.vs_image_1;
        object.vs_image_2 = newStatusVersion.vs_image_2;
        object.vs_image_3 = newStatusVersion.vs_image_3;
        object.vs_image_4 = newStatusVersion.vs_image_4;
        object.vs_image_5 = newStatusVersion.vs_image_5;
        object.vs_image_6 = newStatusVersion.vs_image_6;
        object.vs_image_7 = newStatusVersion.vs_image_7;
        object.vs_image_8 = newStatusVersion.vs_image_8;
        object.vs_image_9 = newStatusVersion.vs_image_9;
        object.vs_image_10 = newStatusVersion.vs_image_10;
        object.vs_image_11 = newStatusVersion.vs_image_11;
        object.vs_image_12 = newStatusVersion.vs_image_12;
        object.vs_image_13 = newStatusVersion.vs_image_13;
        object.vs_image_14 = newStatusVersion.vs_image_14;
        object.vs_image_15 = newStatusVersion.vs_image_15;
        object.zip = newStatusVersion.zip;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_splint) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_minivints) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_template) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment_2d) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_braces) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_aligners) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'splint',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_splint = newStatusVersion.status_splint;
        object.istl_20 = newStatusVersion.istl_20;
        object.splint_image_1 = newStatusVersion.splint_image_1;
        object.splint_image_2 = newStatusVersion.splint_image_2;
        object.splint_image_3 = newStatusVersion.splint_image_3;
        object.splint_image_4 = newStatusVersion.splint_image_4;
        object.splint_image_5 = newStatusVersion.splint_image_5;
        object.splint_image_6 = newStatusVersion.splint_image_6;
        object.splint_image_7 = newStatusVersion.splint_image_7;
        object.splint_image_8 = newStatusVersion.splint_image_8;
        object.splint_image_9 = newStatusVersion.splint_image_9;
        object.splint_image_10 = newStatusVersion.splint_image_10;
        object.gif_splint = newStatusVersion.gif_splint;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_aligners) < 0 &&
        (doctorVisibleStatuses.indexOf(object.status_minivints) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_template) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_equipment_2d) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_splint) > 0 ||
            doctorVisibleStatuses.indexOf(object.status_braces) > 0)
    ) {
        newStatusVersion = findNearestVisibleStatus(
            object.id,
            'aligners',
            object.version,
            allOrderversions,
            alreadyFound
        );
        if (newStatusVersion === null) {
            return false;
        }
        object.status_aligners = newStatusVersion.status_aligners;
        object.vs_image_1 = newStatusVersion.vs_image_1;
        object.vs_image_2 = newStatusVersion.vs_image_2;
        object.vs_image_3 = newStatusVersion.vs_image_3;
        object.vs_image_4 = newStatusVersion.vs_image_4;
        object.vs_image_5 = newStatusVersion.vs_image_5;
        object.vs_image_6 = newStatusVersion.vs_image_6;
        object.vs_image_7 = newStatusVersion.vs_image_7;
        object.vs_image_8 = newStatusVersion.vs_image_8;
        object.vs_image_9 = newStatusVersion.vs_image_9;
        object.vs_image_10 = newStatusVersion.vs_image_10;
        object.vs_image_11 = newStatusVersion.vs_image_11;
        object.vs_image_12 = newStatusVersion.vs_image_12;
        object.vs_image_13 = newStatusVersion.vs_image_13;
        object.vs_image_14 = newStatusVersion.vs_image_14;
        object.vs_image_15 = newStatusVersion.vs_image_15;
        object.zip = newStatusVersion.zip;
    }

    if (
        doctorVisibleStatuses.indexOf(object.status_minivints) < 0 ||
        doctorVisibleStatuses.indexOf(object.status_template) < 0 ||
        doctorVisibleStatuses.indexOf(object.status_equipment) < 0 ||
        doctorVisibleStatuses.indexOf(object.status_equipment_2d) < 0 ||
        doctorVisibleStatuses.indexOf(object.status_braces) < 0 ||
        doctorVisibleStatuses.indexOf(object.status_splint) < 0 ||
        doctorVisibleStatuses.indexOf(object.status_aligners) < 0
    ) {
        visibleForDoctor = false;
    }

    const prevStatus = previousOrderversion;
    if (previousOrderversion) {
        if (
            object.status_minivints === prevStatus.status_minivints &&
            object.status_template === prevStatus.status_template &&
            object.status_equipment === prevStatus.status_equipment &&
            object.status_equipment_2d === prevStatus.status_equipment_2d &&
            object.status_braces === prevStatus.status_braces &&
            object.status_splint === prevStatus.status_splint &&
            object.status_aligners === prevStatus.status_aligners &&
            object.status === prevStatus.status
        ) {
            visibleForDoctor = false;
        }
    }
    return visibleForDoctor;
};

export const prepareOrderVersionsForDoctor = (
    orderversions: any[],
    revisionId: string,
    role: string
) => {
    if (role !== 'doctor') {
        return orderversions;
    }

    let result: any = [];
    const versionsRaw = uniq(map(orderversions, 'version'));

    for (let j = 0; j < versionsRaw.length; j += 1) {
        const resultByVersion = [];
        const orderVersionsByVersion = orderBy(
            filter(orderversions, { version: versionsRaw[j] }),
            ['design_date'],
            ['desc']
        );
        for (let i = orderVersionsByVersion.length - 1; i >= 0; i -= 1) {
            let visibleForDoctor = true;
            const object = orderVersionsByVersion[i];
            visibleForDoctor = getOrderVersionVisibility(
                object,
                resultByVersion.length > 0
                    ? resultByVersion[resultByVersion.length - 1]
                    : undefined,
                orderVersionsByVersion,
                false
            );

            if (visibleForDoctor) {
                resultByVersion.push(object);
            }
        }
        result = result.concat(resultByVersion);
    }

    const sortedResult = orderBy(result, ['design_date'], ['desc']);
    return sortedResult;
};

export const checkOrderVisibility = (order: any, role: string) => {
    let result = false;
    if (role !== 'doctor') {
        return true;
    }
    const orderversions = orderBy(
        order.orderversions,
        ['design_date'],
        ['desc']
    );
    const preparedOrderversions = prepareOrderVersionsForDoctor(
        orderversions.slice(1),
        order.id,
        role
    );
    result = getOrderVersionVisibility(
        order,
        preparedOrderversions[0],
        preparedOrderversions,
        true
    );

    return result;
};
